import React from 'react';

import {
  ListGroup, ListGroupItem,
} from 'react-bootstrap';

import ContactSpan from 'components/contact/span';

const ContactListDisplay = ({ contacts }) => {
  if (contacts.isEmpty()) {
    return <span className="text-muted">No Contacts</span>;
  }

  return <ListGroup className="text-center">
    {contacts.toArray().map((c, idx) => (
      <ListGroupItem key={`${c.contact_type.key}_${c.value}_${idx}`}>
        <ContactSpan contact={c} />
      </ListGroupItem>
    ))}
  </ListGroup>;
}


export default ContactListDisplay;