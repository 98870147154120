import React, { useMemo, lazy, Suspense } from 'react';

import {
  Route,
  Switch
} from 'react-router-dom';

import UserStore, { UserContext } from 'stores/userStore';

import Settings from './settings';


import { ProviderProfileManager } from 'provider/profile';
import ContractRedirect from 'components/contract/redirect'

import InvoiceRedirect from 'components/invoice/redirect'

import Limbo from './limbo';
import { UserType } from 'models/user';
import useFluxStore from 'flux-hooks';


const ProfileRoute = lazy(() => import('components/profile/go'))
const ProviderRoutes = lazy(() => import('provider/provider_routes'))
const ClientRoutes = lazy(() => import('client/client_routes'))


export default function Main() {
  const { user, settings } = useFluxStore(UserStore,
    (p, store) => ({ user: store.user, settings: store.settings }))

  const MainContent = useMemo(() => {
    switch (user.user_type) {
      case UserType.PROVIDER: return ProviderRoutes
      case UserType.CLIENT: return ClientRoutes
      default: return Limbo
    }
  }, [user.user_type])



  return <UserContext.Provider value={{ user, settings }}>
    <div className="container" >
      <Suspense fallback={<h1>Loading...</h1>}>
        <Switch>
          <Route path="/settings" component={Settings} />
          <Route exact path="/provider/profile" component={ProviderProfileManager} />
          <Route exact path="/p/:profile_key" component={ProfileRoute} />
          <Route exact path="/project/new" component={ClientRoutes} />
          <Route exact path="/contract/:contract_key" component={ContractRedirect} />
          <Route exact path="/invoice/:invoice_uid" component={InvoiceRedirect} />
          <Route component={MainContent} />
        </Switch>
      </Suspense>
    </div>
  </UserContext.Provider>
}