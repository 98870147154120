import ProfileType from './profile_type'
import TranslatorProfile from './translator'
import TranscriberProfile from './transcriber'
import Profile from './profile'
import WriterProfile from './writer'


export { ProfileType, Profile, TranslatorProfile, TranscriberProfile }
export function ProfileClassFromType(type) {
  switch (type) {
    case ProfileType.TRANSLATOR:
      return TranslatorProfile
    case ProfileType.TRANSCRIBER:
      return TranscriberProfile
    case ProfileType.WRITER:
      return WriterProfile
    default:
      throw Error("Unhandled profile type", type)
  }
}

export function fromJS(d) {
  const type = ProfileType.get(d.profile_type)
  return ProfileClassFromType(type).fromJS(d)
}

export const rate_unit_from_profile_type = profile_type => ProfileClassFromType(profile_type)
