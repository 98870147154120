import React from 'react';
import filesize from 'filesize';

import { analytics } from 'segment'

const ResumeLink = ({ info, user_key, style = {}, ...other }) => <a
  href={info.resume_url}
  onClick={() => analytics.track(`Contract Open Resume`, { category: 'contract', label: `[${info.name}][${user_key}]` })}
  target="_blank" rel="noopener noreferrer"
>
  <p>{info.resume.filename} <strong>{filesize(info.resume.file ? info.resume.file.size : info.resume.file_size)}</strong><br /></p>
</a >

export default React.memo(ResumeLink);
