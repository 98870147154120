import Enum from 'enum';

import dispatcher from 'dispatcher';

import fetchPlus from 'fetchPlus';


export const ActionTypes = new Enum([
  'CONTRACTS_FOR_PROJECT',
  'UPDATE_CONTRACTS',
  'MY_CONTRACTS'
]);

export const actions = {
  get_for_project(project_key) {
    return fetchPlus({
      url: `/api/contract/get_for_project/${project_key}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.CONTRACTS_FOR_PROJECT,
          contracts: json.contracts,
          project_key
        });
      }
    });
  },

  get(contract_key) {
    return fetchPlus({
      url: `/api/contract/get/${contract_key}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_CONTRACTS,
          contracts: [json.contract]
        });
      }
    });
  },

  propose_contract(contract) {
    return fetchPlus({
      url: '/api/contract/propose_contract',
      body_obj: { contract },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_CONTRACTS,
          contracts: [json.contract]
        });
      }
    });
  },

  accept_proposal(contract_key) {
    return fetchPlus({
      url: '/api/contract/accept_proposal',
      body_obj: { contract_key },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_CONTRACTS,
          contracts: [json.contract]
        });
      }
    });
  },

  reject_proposal(contract_key, reason) {
    return fetchPlus({
      url: '/api/contract/reject_proposal',
      body_obj: { contract_key, reason },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_CONTRACTS,
          contracts: [json.contract]
        });
      }
    });
  },

  cancel_proposed_contract(contract_key) {
    return fetchPlus({
      url: '/api/contract/cancel_proposed_contract',
      body_obj: { contract_key },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_CONTRACTS,
          contracts: [json.contract]
        });
      }
    });
  }
};
