import React from 'react';
import FormGroupCols from 'components/form_group_cols';
import ContactListDisplay from 'components/contact/list_display';
import UserPhoto from 'components/user/photo';
import ResumeLink from 'components/user/resume_link';


import {
  Form,

} from 'react-bootstrap';

import DomainList from './domain_list';

const UserInfo = ({ info, user_key, hide_name = false, style, ...other }) => {
  return <Form className="text-center">
    <FormGroupCols label="Photo">
      <UserPhoto info={info} />
    </FormGroupCols>
    {!hide_name &&
      <FormGroupCols label="Display Name">
        <h4>{info.name}</h4>
      </FormGroupCols>
    }
    <FormGroupCols label='Contact'>
      <ContactListDisplay contacts={info.contacts} />
    </FormGroupCols>
    {info.resume &&
      <FormGroupCols label='Resume'>
        <ResumeLink info={info} user_key={user_key} />
      </FormGroupCols>
    }
    {!info.domains.isEmpty() &&
      <FormGroupCols label='Domains'>
        <DomainList domains={info.domains} />
      </FormGroupCols>
    }
  </Form>
}

export default React.memo(UserInfo);
