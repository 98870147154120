import * as Immutable from 'immutable';
import LangStore from 'stores/langStore';

export default class Language extends Immutable.Record({
  key: undefined,
  script: undefined,
  language: undefined
}) {
  get display() {
    return this.language;
  }

  get display_with_script() {
    if (this.language === this.script) {
      return this.language;
    }
    return `${this.language} (${this.script})`;
  }
}

export class LanguageRate extends Immutable.Record({
  src: undefined,
  rate: undefined
}) {

  static fromJS(jsObj) {
    return new LanguageRate(jsObj);
  }

  get key() {
    return this.src;
  }

  get lang() {
    return LangStore.lang(this.get('src'))
  }

  get rate_display() {
    const r = this.get('rate');
    return r || '';
  }

  get has_rate() {
    return Number.isFinite(parseFloat(this.rate));
  }
}
