import fetchPlus from 'fetchPlus';

import Enum from 'enum';
import dispatcher from 'dispatcher';
import * as user from './user'

export const ActionTypes = new Enum([
  'IFSC_DETAILS',
  'UPDATE_INVOICES',
  'INVOICES_FOR_PROJECT'
]);

export const actions = {
  ifsc(code) {
    return fetchPlus({
      url: `/api/billing/ifsc/${code}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.IFSC_DETAILS,
          details: json.details
        });
      }
    });
  },

  update_payment_settings(payment_settings) {
    return fetchPlus({
      url: '/api/billing/update_payment_settings',
      body_obj: {
        payment_settings
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: user.ActionTypes.USER_SETTINGS,
          settings: json.settings
        });
      }
    });
  },

  request_payment(assignments, adjustments) {
    return fetchPlus({
      url: '/api/billing/request_payment',
      body_obj: {
        assignments,
        adjustments
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_INVOICES,
          invoices: json.invoices
        });
      }
    });
  },

  refresh(invoice_uid, test_mode = false) {
    return fetchPlus({
      url: '/api/billing/refresh',
      body_obj: {
        invoice_uid,
        test_mode
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_INVOICES,
          invoices: json.invoices
        });
      }
    });
  },

  get_for_project(project_key) {
    return fetchPlus({
      url: `/api/billing/get_for_project/${project_key}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.INVOICES_FOR_PROJECT,
          invoices: json.invoices,
          project_key
        });
      }
    });
  },

  cancel_invoice(invoice_key) {
    return fetchPlus({
      url: '/api/billing/cancel_invoice',
      body_obj: {
        invoice_key
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_INVOICES,
          invoices: json.invoices
        });
      }
    });
  },

  reject_invoice(invoice_key, reason) {
    return fetchPlus({
      url: '/api/billing/reject_invoice',
      body_obj: {
        invoice_key, reason
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_INVOICES,
          invoices: json.invoices
        });
      }
    });
  },

  get_by_uid(invoice_uid) {
    return fetchPlus({
      url: `/api/billing/get_by_uid/${invoice_uid}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_INVOICES,
          invoices: [json.invoice],
        });
      }
    });
  },

  write_review(invoice_uid, review) {
    return fetchPlus({
      url: `/api/billing/write_review/${invoice_uid}`,
      body_obj: {
        review
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_INVOICES,
          invoices: [json.invoice]
        });
      }
    });
  }
}