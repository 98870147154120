// We use this because from notifications that involve a contract
// the user will hit /contract/:contract_key 
// based on user information, we either forward them just to the project
// or if it's the client, we forward them to the team member's contract page in the project

import React, {
  useContext
} from 'react';


import ContractStore from 'stores/contractStore';
import { UserContext } from 'stores/userStore';

import { UserType } from 'models/user';

import { Redirect } from 'react-router-dom';

import useFluxStore from 'flux-hooks';

export default function ContractRedirect({ match }) {

  const { contract_key } = match.params;
  const contract = useFluxStore(ContractStore, (p, store) => store.get_by_key(contract_key));
  const { user } = useContext(UserContext)

  if (!contract) {
    return <h1>Redirecting...</h1>;
  }

  if (user.user_type === UserType.PROVIDER) {
    return <Redirect to={`/project/${contract.project_key}#contracts`} />;
  } else {
    return <Redirect to={`/project/${contract.project_key}/team/${contract.provider_user_key}#contracts`} />
  }
}
