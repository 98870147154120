import React, {
  Component
} from 'react';

import {
  Button,
  ButtonGroup,
  FormControl,
  ListGroupItem,
  Well,
} from 'react-bootstrap';

import { actions } from 'actions/support';

import UserStore from 'stores/userStore';

import ModalDismissable from 'components/modal_dismissable';
import FormGroupCols from 'components/form_group_cols';

import isEmail from 'validator/lib/isEmail';

import { analytics } from 'segment'

export default class SupportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      enter_email: true,
      msg: '',
    };
  }

  close = () => {
    this.modal.close();
  }

  show = () => {
    analytics.page({ title: 'Support', path: `/modal/support` })
    this.setState({
      email: UserStore.user.email,
      enter_email: !Boolean(UserStore.user.email),
      msg: '',
    });

    this.modal.show();
  }

  onMsgChange = value => {
    this.setState({
      msg: value
    });
  }

  onEmailChange = value => {
    this.setState({
      email: value
    });
  }

  onSend = () => {
    actions.msg(this.state.email, this.state.msg)
      .then(() => {
        alert("Message Sent")
        this.close()
      })
      .catch(err => alert("Error sending message\n" + err));

    analytics.track('Support Send Message', { category: 'help' })
  }

  render() {
    const { email, enter_email, msg } = this.state;

    const msg_valid = msg.length > 5;
    const email_valid = !enter_email || isEmail(email);

    return (
      <ModalDismissable
        bsSize="large"
        ref={m => (this.modal = m)}
        title='Support'
        footer={
          <ButtonGroup>
            <Button disabled={!(msg_valid && email_valid)} bsStyle="success"
              onClick={this.onSend}
            >
              Send
            </Button>
            <Button onClick={this.close}>
              Cancel
            </Button>
          </ButtonGroup>
        }
      >
        <Well>
          <ListGroupItem>

            <FormGroupCols label="Email" valid={email_valid}>
              {enter_email ?
                <FormControl
                  type="text"
                  placeholder="Email address where we can reach you"
                  onChange={e => this.onEmailChange(e.target.value)}
                  value={email}
                />
                :
                <span>We'll get back to you at <strong>{email}</strong></span>
              }
            </FormGroupCols>

            <FormGroupCols label="Message" valid={msg_valid}>
              <FormControl
                componentClass="textarea"
                placeholder="Let us know what we can help you with."
                onChange={e => this.onMsgChange(e.target.value)}
                value={msg}
              />
            </FormGroupCols>
          </ListGroupItem>
        </Well>
      </ModalDismissable>
    );
  }
}
