import React from 'react';

import {
  Glyphicon
} from 'react-bootstrap';

const glyph_style = {
  fontSize: '1.5em',
  marginRight: '5px'
}

const count_style = {
  top: "-5px",
  position: "relative"
}

export const BellIcon = ({ count, style = {} }) => {
  return (
    <span style={style}>
      <Glyphicon id="bell-icon" style={glyph_style} glyph="bell" />
      {count > 0 &&
        <span id="notif-count" style={count_style} className="label label-danger">{count}</span>
      }
    </span>
  );
};


export const HelpIcon = ({ style = {} }) => {
  return (
    <span style={style}>
      <Glyphicon id="help-icon" style={glyph_style} glyph="question-sign" />
    </span>
  );
};

