import {
  List,
  OrderedSet,
  Record,
} from 'immutable';

import Enum from 'enum';

import { LanguagePairRate } from 'models/language_pair';

import ContractType from './contract_type'
import ProfileStore from 'stores/profileStore';
import ProjectStore from 'stores/projectStore';
import Cost, { CostType } from 'models/cost';
import { LanguageRate } from 'models/language';

export const ContractState = new Enum({
  PROPOSED: 0,
  ACCEPTED: 1,
  REPLACED: 5,
  REJECTED: 8,
  CANCELLED: 9,
});



export const Contract = defaultValues => class extends Record({
  key: undefined,
  project_key: undefined,
  profile_key: undefined,
  provider_user_key: undefined,
  agreed: false,
  notes: '',
  reject_reason: '',
  state: ContractState.PROPOSED,
  contract_type: ContractType.BASE,
  cost_type: CostType.CONTRACT_RATE,
  creator: '',
  created: undefined,
  updated: undefined,
  ...defaultValues
}) {
  get profile() {
    return ProfileStore.get_profile(this.profile_key);
  }

  get project() {
    return ProjectStore.project(this.project_key);
  }

  get active() {
    return this.state === ContractState.ACCEPTED;
  }

  get url() {
    return `/contract/${this.key}`;
  }

  get cost_size_name() {
    return this.ASSIGNMENT_CLASS.cost_size_name
  }

  new_cost(rate, size) {
    switch (this.cost_type) {
      case CostType.CONTRACT_RATE:
        return new Cost({
          cost_type: CostType.CONTRACT_RATE,
          rate: rate,
          work: size,
          work_unit: this.work_unit,
          total: rate * size
        })
      case CostType.FIXED:
        return new Cost({
          cost_type: CostType.FIXED,
          rate: undefined,
          work_unit: this.work_unit,
          total: undefined
        })
      default:
        throw Error("Unknown CostType")
    }
  }

  new_assignment(document, req_key, size) {
    throw Error("Not Implemented")
  }

  get reqs() {
    throw Error("Not Implemented")
  }

  as_new() {
    return this.merge({
      key: undefined,
      state: ContractState.PROPOSED,
      created: undefined,
      updated: undefined
    });
  }

  get valid() {
    return {
      agreed: this.agreed,
      notes: Boolean(this.notes.trim())
    }
  }

  static supJS(jsObj) {
    const ret = jsObj;
    ret.state = ContractState.get(ret.state);
    ret.contract_type = ContractType.get(ret.contract_type);
    ret.cost_type = CostType.get(ret.cost_type)
    ret.created = Date.parse(ret.created);
    ret.updated = Date.parse(ret.updated);

    return ret;
  }
};


export const LangPairContract = defaultValues => class extends Contract({
  lang_pair_rates: List(),
  ...defaultValues
}) {
  get lang_pairs() {
    return OrderedSet(this.lang_pair_rates.map(lpr => lpr.lang_pair));
  }

  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      lang_pair_rates: !this.lang_pair_rates.isEmpty() &&
        (this.cost_type === CostType.FIXED || this.lang_pair_rates.every(lpr => lpr.has_rate)),
      ...super.valid
    }

    this._valid['all'] = Object.values(this._valid).every(v => v);

    return this._valid;
  }

  get reqs() {
    return this.lang_pair_rates
  }

  lpr_for_req(req_key) {
    return this.lang_pair_rates.find(ilpr => ilpr.key === req_key);
  }

  remove_lpr(lpr) {
    const idx = this.lang_pair_rates.indexOf(lpr);
    this.lang_pair_rates = this.lang_pair_rates.remove(idx);
  }

  set_lpr(lpr) {
    const idx = this.lang_pair_rates.findIndex(ilpr => ilpr.key === lpr.key);
    if (idx === -1) {
      this.lang_pair_rates = this.lang_pair_rates.push(lpr);
    } else {
      this.lang_pair_rates = this.lang_pair_rates.set(idx, lpr);
    }
  }

  cost(lang_pair, cost_size) {
    const { rate } = this.rate_for_req(lang_pair.key);
    if (isNaN(rate) || isNaN(cost_size)) {
      return null;
    }

    return Number(rate * cost_size);
  }

  rate_for_req(req_key) {
    return this.lang_pair_rates.find(ilpr => ilpr.key === req_key)
  }

  new_assignment(document, req_key, size) {
    const lpr = this.rate_for_req(req_key)
    const cost = this.new_cost(lpr.rate, size)

    return new this.ASSIGNMENT_CLASS({
      contract_key: this.key,
      profile_key: this.profile_key,
      document_key: document.key,
      lang_pair: lpr.lang_pair,
      cost
    })
  }

  static supJS(jsObj) {
    const ret = super.supJS(jsObj);
    ret.lang_pair_rates = List(jsObj.lang_pair_rates.map(v => LanguagePairRate.fromJS(v)));
    return jsObj;
  }
}


export const LangContract = defaultValues => class extends Contract({
  lang_rates: List(),
  ...defaultValues
}) {

  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      lang_rates: !this.lang_rates.isEmpty() &&
        (this.cost_type === CostType.FIXED || this.lang_rates.every(lr => lr.has_rate)),
      ...super.valid
    }

    this._valid['all'] = Object.values(this._valid).every(v => v);

    return this._valid;
  }

  get reqs() {
    return this.lang_rates
  }

  lr_for_lang(lang_key) {
    return this.lang_rates.find(lr => lr.src === lang_key);
  }

  remove_lr(lr) {
    const idx = this.lang_rates.indexOf(lr);
    this.lang_rates = this.lang_rates.remove(idx);
  }

  set_lr(lr) {
    const idx = this.lang_rates.findIndex(ilr => ilr.key === lr.key);
    if (idx === -1) {
      this.lang_rates = this.lang_rates.push(lr);
    } else {
      this.lang_rates = this.lang_rates.set(idx, lr);
    }
  }

  // cost(lang_pair, cost_size) {
  //   const { rate } = this.lpr_for_lp(lang_pair);
  //   if (isNaN(rate) || isNaN(cost_size)) {
  //     return null;
  //   }

  //   return Number(rate * cost_size);
  // }

  rate_for_req(req_key) {
    return this.lang_rates.find(ilr => ilr.key === req_key)
  }


  new_assignment(document, req_key, size) {
    const lr = this.rate_for_req(req_key)
    const cost = this.new_cost(lr.rate, size)

    return new this.ASSIGNMENT_CLASS({
      contract_key: this.key,
      profile_key: this.profile_key,
      document_key: document.key,
      src: lr.src,
      cost
    })
  }

  static supJS(jsObj) {
    const ret = super.supJS(jsObj);
    ret.lang_rates = List(jsObj.lang_rates.map(v => LanguageRate.fromJS(v)));
    return jsObj;
  }
}
