import {
  List,
  OrderedSet,
  Record,
} from 'immutable';

import { LanguagePairRate } from 'models/language_pair';

import ProfileType from './profile_type'

import PersonalInfo from 'models/personal_info';
import { LanguageRate } from 'models/language';
import { AttachmentItem } from 'models/attachment';

export const Profile = defaultValues => class extends Record({
  key: undefined,
  user_key: undefined,
  added: undefined,
  info: new PersonalInfo(),
  description: '',
  profile_type: ProfileType.BASE,
  available: false,
  has_gst: undefined,
  busy: undefined,
  negotiable: false,
  portfolio: List(),
  ...defaultValues
}) {
  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      description: Boolean(this.description.trim()),
      info: this.info.valid.contacts,
      portfolio: this.portfolio.every(att => att.valid.all)
    }

    return this._valid;
  }

  get valid_all() {
    return Object.values(this.valid).every(v => v);
  }

  static supJS(ret) {
    ret.profile_type = ProfileType.get(ret.profile_type);
    ret.info = PersonalInfo.fromJS(ret.info, ret.user_key);
    ret.portfolio = List(ret.portfolio?.map(p => AttachmentItem.fromJS(p, 'profile', ret.key)))
    ret.added = Date.parse(ret.added);
    return ret;
  }

  match_req() {
    throw new Error("Should be implemented by sub-class")
  }

  match_reqs(project) {
    return project.reqs.filter(r => this.match_req(r))
  }

  url(project_key) {
    return `/project/${project_key}/team/${this.key}`;
  }

  get label_for_ga() {
    return `[${this.info.name}][${this.user_key}]`
  }
};

export const LangPairProfile = defaultValues => class extends Profile({
  lang_pair_rates: List(),
  ...defaultValues
}) {
  get lang_pairs() {
    return OrderedSet(this.lang_pair_rates).map(lpr => lpr.lang_pair);
  }

  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      lang_pair_rates: !this.lang_pair_rates.isEmpty(),
      ...super.valid
    }

    this._valid['all'] = Object.values(this._valid).every(v => v);

    return this._valid;
  }

  lpr_for_lp(lp) {
    return this.lang_pair_rates.find(ilpr => ilpr.key === lp.key);
  }

  match_lp(project, contract) {
    const proj_match = project.lang_pairs_for_profile_type(this.profile_type).intersect(this.lang_pairs)
    return contract ?
      proj_match.union(contract.lang_pairs) :
      proj_match
  }

  match_req(req) {
    if (req.profile_type !== this.profile_type) return false
    return this.lpr_for_lp(req.lang_pair)
  }

  unmatch_lp(...args) {
    return this.lang_pairs.subtract(this.match_lp(...args))
  }

  static supJS(jsObj) {
    const sup = super.supJS(jsObj);
    sup.lang_pair_rates = List(jsObj.lang_pair_rates
      .map(p => LanguagePairRate.fromJS(p)));
    return sup;
  }
}


export const LangProfile = defaultValues => class extends Profile({
  lang_rates: List(),
  ...defaultValues
}) {
  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      lang_rates: !this.lang_rates.isEmpty(),
      ...super.valid
    }

    return this._valid;
  }

  get langs() {
    return this.lang_rates.map(lr => lr.lang)
  }

  static supJS(jsObj) {
    const sup = super.supJS(jsObj);
    sup.lang_rates = List(jsObj.lang_rates
      .map(p => LanguageRate.fromJS(p)));
    return sup;
  }


  lr_for_lang(lang_key) {
    return this.lang_rates.filter(lr => lr.src === lang_key).first()
  }

}
export default Profile