import Enum from 'enum';

import dispatcher from 'dispatcher';

import fetchPlus from 'fetchPlus';

export const ActionTypes = new Enum([
  'LANGS_LIST_UPDATE'
]);


export const actions = {
  fetch_langs() {
    return fetchPlus({
      url: '/api/langs/get_all.json',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.LANGS_LIST_UPDATE,
          langs: json.langs
        });
      }
    });
  }
};
