import fetchPlus from 'fetchPlus';

/* eslint-disable no-unused-vars */
class Config {
  constructor() {
    this.DEV_SERVER = false
    fetchPlus({
      url: '/api/dev_server',
      success: ({ dev_server, razorpay_key, gst_rate }) => {
        this.DEV_SERVER = dev_server
        this.RAZORPAY_KEY_ID = razorpay_key
        this.GST_RATE = gst_rate
      }
    })

    this.SEGMENT_WRITE_KEY = window.location.host === 'fieldscope.in' ?
      'up77NFrW1dSb0gEr1bEJJMLG7c8PFvF4' : ''


    //   this.GOOGLE_ANALYTICS_CODE = window.location.host === 'fieldscope.in' ?
    //     'UA-120388484-1' : 'UA-120388484-2';
  }
}

const config = new Config();
export default config;
/* eslint-enable no-unused-vars */
