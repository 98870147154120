import Enum from 'enum';

import dispatcher from 'dispatcher';

import fetchPlus from 'fetchPlus';

export const ActionTypes = new Enum([
  'MY_PROJECTS',
  'CREATE_PROJECT',
  'UPDATE_PROJECT',
  'DELETE_PROJECT'
]);

export const actions = {

  my() {
    return fetchPlus({
      url: '/api/proj/my',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.MY_PROJECTS,
          projects: json.projects
        });
      }
    });
  },

  fetch_project(project_key) {
    return fetchPlus({
      url: `/api/proj/get/${project_key}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_PROJECT,
          project: json.project
        });
      }
    });
  },

  create_project(project) {
    return fetchPlus({
      url: '/api/proj/create_project',
      body_obj: {
        project
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.CREATE_PROJECT,
          project: json.project
        });
      }
    });
  },

  update_project(project) {
    return fetchPlus({
      url: '/api/proj/update_project',
      body_obj: {
        project
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_PROJECT,
          project: json.project
        });
      }
    });
  },

  delete_project(project_key) {
    return fetchPlus({
      url: '/api/proj/delete_project',
      body_obj: { project_key },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.DELETE_PROJECT,
          project_key: json.project_key
        });
      }
    });
  }
};
