import React, {
  lazy, Suspense
} from 'react';

import useFluxStore from 'flux-hooks';

import {
  Route,
  Switch
} from 'react-router-dom';


import {
  Breadcrumb,
  Jumbotron,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';

import {
  LinkContainer
} from 'react-router-bootstrap';

import UserStore from 'stores/userStore';

import EmailSettings from './email'

import { UserType } from 'models/user';

import SetPassword from 'auth/set_password';
import PersonalInfo from './personal_info';

const PaymentSettings = lazy(() => import('./payments'));


export default function Settings() {

  const is_provider = useFluxStore(UserStore, (p, store) => store.user.user_type === UserType.PROVIDER)

  const SettingsList = () => <React.Fragment>
    <Breadcrumb className="ellipsis">
      <LinkContainer to="/">
        <Breadcrumb.Item>
          Home
        </Breadcrumb.Item>
      </LinkContainer>
      <Breadcrumb.Item active>
        Settings
      </Breadcrumb.Item>
    </Breadcrumb>
    <h1 className="text-center">Settings</h1>
    <Jumbotron className="text-center">
      <ListGroup>
        <LinkContainer to="/settings/personal_info">
          <ListGroupItem>
            Personal Info
          </ListGroupItem>
        </LinkContainer>
        <LinkContainer to="/settings/password">
          <ListGroupItem>Password
          </ListGroupItem>
        </LinkContainer>
        {is_provider &&
          <React.Fragment>
            <LinkContainer to="/settings/payments">
              <ListGroupItem>
                Payments
              </ListGroupItem>
            </LinkContainer>
          </React.Fragment>
        }
        <LinkContainer to="/settings/email">
          <ListGroupItem>
            Email
          </ListGroupItem>
        </LinkContainer>
      </ListGroup>
    </Jumbotron>
  </React.Fragment>



  return <div className="container">
    <Suspense fallback={<h1 >Loading...</h1>}>
      <Switch>
        <Route exact path="/settings/email" component={EmailSettings} />
        <Route exact path="/settings/payments" component={PaymentSettings} />
        <Route exact path="/settings/personal_info" component={PersonalInfo} />
        <Route exact path="/settings/password" render={
          props => <SetPassword {...props} from_settings />
        } />
        <Route>
          <div className="container">
            <SettingsList />
          </div>
        </Route>
      </Switch>
    </Suspense>
  </div>

}
