import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { Map, OrderedSet, Record } from 'immutable';

import * as user from 'actions/user';

import { ActionTypes, actions } from 'actions/activity';

import Activity from 'models/activity';

class ActivityState extends Record({
  activity: Map()
}) {
  add_activity(type, key, js_acts) {
    const acts = js_acts.map(jsObj => Activity.fromJS(jsObj));
    let type_set = this.activity.get(type) || Map();
    const act_set = type_set.get(key) || OrderedSet();

    type_set = type_set.set(key, act_set
      .union(acts)
      .sortBy(a => -a.created));

    return this.setIn(['activity', type], type_set);
  }

  has_activity(type, key) {
    return this.activity.has(type) && this.activity.get(type).has(key);
  }

  get_activity(type, key) {
    if (!this.has_activity(type, key)) return OrderedSet();
    return this.activity.get(type).get(key);
  }
}

class ActivityStore extends ImmutableReduceStore {
  getInitialState() {
    return new ActivityState();
  }

  get(type, key, no_fetch = false) {
    if (this._state.has_activity(type, key)) {
      return this._state.get_activity(type, key);
    }

    if (!no_fetch) {
      actions.fetch(type, key);
    }
    return null;
  }


  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.ACTIVITY_UPDATE: {
        const { activity_type, key, activity } = action;
        return state.add_activity(activity_type, key, activity);
      }

      case user.ActionTypes.USER_STATUS: {
        if (!action.logged_in) return this.getInitialState();
        return state;
      }

      default:
        return state;
    }
  }
}

window.activityStore = new ActivityStore();
export default window.activityStore;
