import { LangPairProfile } from './profile'
import ProfileType from './profile_type.js'

export default class TranscriberProfile extends LangPairProfile({
  profile_type: ProfileType.TRANSCRIBER,
  rate_unit: "minute"
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    return new TranscriberProfile(sup);
  }
}