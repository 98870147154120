import {
  List, Record, OrderedSet
} from 'immutable';

import Enum from 'enum';

import Contact from 'models/contact'
import UploadedFile, { ImageUpload } from 'models/uploaded_file';
import blank_profile from 'auth/blankprofile.png'

const DEFAULT_PHOTO = blank_profile;

export const DomainTypes = new Enum({
  HEALTH: 0,
  LIVELIHOOD: 1,
  EDUCATION: 2,
  ENVIRONMENT: 3,
  COMMUNITY: 4,
  GENDER: 5,
  GOVERNANCE: 6,
  LEGAL: 7,
  SANITATION: 8,
  DISABILITY: 9,
  CULTURE: 10,
});

export function DomainTypeString(domain) {
  switch (domain) {
    case DomainTypes.HEALTH: return "Health"
    case DomainTypes.LIVELIHOOD: return "Livelhoods"
    case DomainTypes.EDUCATION: return "Education"
    case DomainTypes.ENVIRONMENT: return "Environment & Energy"
    case DomainTypes.COMMUNITY: return "Community Development"
    case DomainTypes.GENDER: return "Gender"
    case DomainTypes.GOVERNANCE: return "Governance"
    case DomainTypes.LEGAL: return "Legal & Rights"
    case DomainTypes.SANITATION: return "Sanitation"
    case DomainTypes.DISABILITY: return "Disability"
    case DomainTypes.CULTURE: return "Culture & Arts"
    default:
      throw Error("Unknown Domain Type", domain)
  }
}

export default class PersonalInfo extends Record({
  name: '',
  contacts: List(),
  domains: OrderedSet(),
  user_key: undefined,
  photo: null,
  photo_update: false,
  resume: null,
  resume_update: false
}) {
  static fromJS(jsObj, user_key) {
    jsObj.user_key = user_key;

    if (jsObj.contacts) {
      jsObj.contacts = List(jsObj.contacts.map(c => Contact.fromJS(c)));
    }

    if (jsObj['photo']) {
      jsObj['photo'] = new ImageUpload(jsObj['photo'])
    }

    if (jsObj['resume']) {
      jsObj['resume'] = new UploadedFile(jsObj['resume'])
    }

    if (jsObj['domains']) {
      jsObj['domains'] = OrderedSet(jsObj['domains'].map(n => DomainTypes.get(n)))
    }

    return new PersonalInfo(jsObj);
  }

  remove_files() {
    return this.withMutations(t => {
      t.set('photo', null);
      t.set('photo_update', false);
      t.set('resume', null);
      t.set('resume_update', false);
    });
  }
  get photo_image() {
    return this.photo ? this.photo.image : DEFAULT_PHOTO;
  }

  get resume_url() {
    return (this.resume && !this.resume.file) ? `/api/auth/resume/${this.user_key}/${this.resume.filename}` : null;
  }

  set_photo(photo, data_url) {
    return this.withMutations(t => {
      t.set('photo', ImageUpload.fromFile(photo, data_url));
      t.set('photo_update', true);
    });
  }

  remove_photo() {
    return this.withMutations(t => {
      t.set('photo', null);
      t.set('photo_update', true);
    });
  }

  set_resume(resume) {
    return this.withMutations(t => {
      t.set('resume', UploadedFile.fromFile(resume));
      t.set('resume_update', true);
    });
  }

  remove_resume() {
    return this.withMutations(t => {
      t.set('resume', null);
      t.set('resume_update', true);
    });
  }

  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      name: this.name.trim().length > 3,
      contacts: this.contacts.isEmpty() ? null : this.contacts.every(c => c.valid)
    }

    this._valid['all'] = Object.values(this._valid).every(v => (v === true || v === null));
    return this._valid;
  }
}