import React, { useRef } from 'react';
import useFluxStore from 'flux-hooks';
import UserStore from 'stores/userStore';


import { Redirect } from 'react-router-dom';

import { Button, Jumbotron } from 'react-bootstrap';
import SupportModal from 'components/support_modal';
import { UserType } from 'models/user';

export default function Wait({ verify_email_only = false }) {
  const [user, logged_in] = useFluxStore(UserStore, (p, userStore) => [userStore.user, userStore.logged_in])

  const support_modal = useRef()

  if (!logged_in) {
    window.location.pathname = ""
  }

  if (user.ready) {
    return <Redirect to="/" />
  }

  return <div className='multiline-left-center'>
    <Jumbotron style={{ padding: '1em' }}>
      <h3 style={{ textAlign: 'center' }}>Thanks for signing up!</h3>

      <div className='multiline-left-center'>
        {user.email_verified ?
          <p className="text-success">
            Your email address ({user.email}) has been verified.
          </p>
          :
          <p>
            Please click the <strong>Verify</strong> button on the email we sent to your address (<strong>{user.email}</strong>).
          </p>
        }
        <p>
          <SupportModal ref={support_modal} />
          {!verify_email_only &&
            <>
              {user.user_type === UserType.CLIENT &&
                <span>
                  We are reviewing your request. You will receive an email with next steps shortly.
                </span>
              }
              {user.user_type === UserType.PROVIDER &&
                <span>
                  Thank you for signing-up!<br />
                  We are reviewing your profile and will get in touch for a follow-up if you’re a good fit.
                  Unfortunately, we cannot get back to all applicants due to high-volumes.
                </span>
              }
              <br />
            </>
          }
          All information shared is confidential.
          If you have any questions, please <Button bsStyle="link" onClick={() => support_modal.current.show()}>contact us</Button>.
        </p>
        <p>
          Thanks,
          <br />- Fieldscope Team
        </p>
      </div>
    </Jumbotron>
  </div>
}