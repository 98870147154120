import matches from 'validator/lib/matches'

import { VALID_PINCODES } from 'components/pincode/pincode_valid'
import { STATES_AND_UT } from 'components/pincode/STATES_AND_UT'
import { GST_CODE_TO_STATE } from './GST_CODE_TO_STATE'

export const isValidIFSC = ifsc => matches(ifsc, /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/)
export const isValidPAN = pan => matches(pan, /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)
export const isValidPIN = pin => VALID_PINCODES.includes(pin)
export const STATE_FROM_GST = gst => GST_CODE_TO_STATE[gst.substring(0, 2)]
export const GST_STATE_STRING = gst => STATES_AND_UT[GST_CODE_TO_STATE[gst.substring(0, 2)]]

export const isValidGST = gst => {
  const pancard_check = pan => {
    // https://en.wikipedia.org/wiki/Permanent_account_number
    // https://www.incometaxindia.gov.in/Forms/tps/1.Permanent%20Account%20Number%20(PAN).pdf
    if (!pan.match(/[A-Z]{5}[\d]{4}[A-Z\d]{1}/)) return [false, 'PAN Format']
    if (!(pan[3].match(/[PCHABGJLEFT]/))) return [false, 'PAN Holder Type']
    return [true,]
  }

  const checksum = gst => {
    let total = 0

    for (var place = 1; place < gst.length; place++) {
      const char = gst.charAt(place - 1)
      const digit = parseInt(char, 36)
      const odd = (place % 2) !== 0
      const factor = odd ? 1 : 2
      const mult = digit * factor
      const quotient = Math.floor(mult / 36)
      const remainder = mult % 36
      const s2 = quotient + remainder
      total += s2
    }

    const int_value = (36 - (total % 36)) % 36
    const checksum_value = int_value.toString(36).toUpperCase()

    return checksum_value === gst[gst.length - 1]
  }

  const gst_check = in_gst => {
    const gst = in_gst.toUpperCase()

    const split = gst.match(/^(\d{2})([A-Z\d]{10})[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/)

    // Doesn't match basic GST format
    if (!split) return [false, "GST Format"]
    const gst_state_code = parseInt(split[1])
    if (gst_state_code < 1 || gst_state_code > 37) return [false, "GST State Code"]
    const pan_test = pancard_check(split[2])
    if (!pan_test[0]) return pan_test

    if (!checksum(gst)) return [false, "GST Checksum"]

    return [true,]
  }

  return gst_check(gst)[0]
}

