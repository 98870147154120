import { LangContract } from './contract'
import ContractType from './contract_type'
import { WorkUnit } from 'models/cost';
import { WriterAssignment } from 'models/assignment';

export default class WriterContract extends LangContract({
  contract_type: ContractType.WRITER,
  verb: 'Write',
  work_unit: WorkUnit.word
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    return new WriterContract(sup);
  }

  get ASSIGNMENT_CLASS() {
    return WriterAssignment
  }
}