import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { Record, OrderedMap, Map, Set } from 'immutable';
import { Invoice, InvoiceState } from 'models/invoice';

import { actions, ActionTypes } from 'actions/billing';
import * as user from 'actions/user';

class InvoiceStore extends ImmutableReduceStore {
  getInitialState() {
    return new Record({
      invoices: OrderedMap(),
      invoices_by_uid: Map(),
      invoices_fetched: Set(),
      project_invoices_fetched: Set()
    })();
  }

  get_by_key(invoice_key) {
    return this._state.invoices.get(invoice_key)
  }

  get_by_uid(invoice_uid) {
    if (!this._state.invoices_by_uid.has(invoice_uid)) {
      actions.get_by_uid(invoice_uid)
    }

    return this._state.invoices_by_uid.get(invoice_uid)
  }

  project_request_count(project_key) {
    return this.project_invoices(project_key).filter(i => i.state === InvoiceState.REQUEST).count()
  }

  project_invoices(project_key) {
    if (!project_key) return Map();

    if (!this.project_fetched(project_key)) {
      actions.get_for_project(project_key);
    }

    return this._state.invoices.filter(inv => inv.project_key === project_key);
  }

  project_fetched(project_key) {
    return this._state.project_invoices_fetched.has(project_key)
  }

  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.UPDATE_INVOICES: {
        const invoices = action.invoices.map(jsObj => Invoice.fromJS(jsObj));

        return state.withMutations(mState => {
          invoices.forEach(inv => {
            mState.setIn(['invoices', inv.key], inv);
            mState.setIn(['invoices_by_uid', inv.uid], inv);
            mState.update('invoices_fetched', s => s.add(inv.key));
          });

          mState.update('invoices', invoices => invoices.sortBy(i => -i.updated))
        });
      }

      case ActionTypes.INVOICES_FOR_PROJECT: {
        const invoices = action.invoices.map(jsObj => Invoice.fromJS(jsObj));
        return state.withMutations(mState => {
          invoices.forEach(inv => {
            mState.setIn(['invoices', inv.key], inv);
            mState.setIn(['invoices_by_uid', inv.uid], inv);
            mState.update('invoices_fetched', s => s.add(inv.key));
          });

          mState.update('invoices', invoices => invoices.sortBy(i => -i.updated))
          mState.update('project_invoices_fetched', s => s.add(action.project_key));
        });
      }

      case user.ActionTypes.USER_STATUS: {
        if (!action.logged_in) {
          return this.getInitialState();
        }

        return state;
      }
      default:
        return state;
    }
  }

}

window.invoiceStore = new InvoiceStore();
export default window.invoiceStore;
