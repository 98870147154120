import {
  OrderedSet,
  Record,
  List,
} from 'immutable';

import Enum from 'enum';

import isURL from 'validator/lib/isURL'

import ProjectStore from 'stores/projectStore';
import { UploadedFile } from 'models/uploaded_file';
import { AttachmentItem } from 'models/attachment';

export const FileType = new Enum({
  DOCUMENT: 0,
  MEDIA: 1
})

export class Document extends Record({
  key: undefined,
  project_key: undefined,
  name: undefined,
  added: undefined,
  updated: undefined,
  archived: undefined,
  deleted: undefined,
  notes: undefined,
  assignment_keys: OrderedSet(),
  src_file: new UploadedFile(),
  src_url: undefined,
  resources: List()
}) {
  static fromJS(jsObj) {
    const ret = jsObj;
    if (ret.added) {
      ret.added = Date.parse(ret.added);
    }
    if (ret.updated) {
      ret.updated = Date.parse(ret.updated);
    }

    ret.archived = Boolean(ret.archived)
    ret.deleted = Boolean(ret.deleted)

    ret.src_file = new UploadedFile(ret.src_file);

    ret.resources = List(ret.resources?.map(p => AttachmentItem.fromJS(p, 'doc', ret.key)))

    ret.assignment_keys = OrderedSet(ret.assignment_keys);
    return new Document(ret);
  }

  isEmpty() {
    return !Boolean(this.key);
  }

  get url() {
    return `/document/${this.key}`;
  }

  get is_link_task() {
    return (!!(this.src_url) || this.src_url === '')
  }

  get downloadable() {
    return !!this.src_file.file_size
  }

  get download_url() {
    return `/api/doc/dl/${this.key}/${this.name}`;
  }

  get project() {
    return ProjectStore.project(this.project_key);
  }

  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      name: !!(this.name && this.name.trim().length > 2),
    }

    if (this.is_link_task) {
      this._valid['src_url'] = isURL(this.src_url, { require_protocol: true })
    }

    this._valid['all'] = Boolean(this._valid.name)

    return this._valid;
  }

}

export default Document;