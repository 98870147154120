import React from 'react';

import { HIWModal } from 'components/hiw'

import { analytics } from 'segment'

export const EasySteps = () => <React.Fragment>
  <ol>
    <p>
      <strong>Four Easy Steps:</strong>
      <li><strong>Create your project</strong> and specify your requirements.</li>
      <li><strong>Browse the providers</strong> and request callbacks.</li>
      <li><strong>Propose a contract</strong> with your chosen providers.</li>
      <li><strong>Upload &amp; Assign</strong> work once they accept the contract.</li>
    </p>
  </ol>
</React.Fragment>

export class ClientHIWModal extends HIWModal {
  EasySteps = EasySteps
  TabDescription = () => <React.Fragment>
    <h5>Three tabs to streamline all your work:</h5>
    <p>
      <span className="text-primary">PROJECT</span>: Define your project requirements<br />
      <span className="text-primary">TEAM</span>: Find the right providers for the job<br />
      <span className="text-primary">WORKSPACE</span>: Upload and manage tasks<br />

    </p>
    <p>
      <strong>Watch the the <a
        href="https://youtu.be/StAJ-SBzQEY"
        onClick={() => analytics.track('Intro Video Client Open', { category: 'help' })}
        target="_blank"
        rel="noopener noreferrer"
      >Client Intro Video here</a></strong>
    </p>
  </React.Fragment>
}