import {
  Record
} from 'immutable';


export default class Activity extends Record({
  key: undefined,
  created: undefined,
  user_displayname: '',
  log: '',
}) {
  static fromJS(jsObj) {
    jsObj.created = Date.parse(jsObj.created);
    return new Activity(jsObj);
  }
}
