import React from 'react'

import {
  Record,
  List,
  OrderedSet,
  Map
} from 'immutable';


import Contact from 'models/contact'
import TeamMember from './team_member'
import { BillingRecord } from './billing_record';
import { Requirement } from './requirements';
import moment from 'moment';

export { TeamMember }

export default class Project extends Record({
  key: undefined,
  startline: undefined,
  deadline: undefined,
  created: undefined,
  org: '',
  brief: '',
  title: '',
  reqs: OrderedSet(),
  contacts: List(),
  contracts: Map(),
  team: Map(),
  owner: '',
  billing: new BillingRecord(),
  locked: false,
}) {
  get srcs() {
    return OrderedSet(this.reqs.map(r => r.lang_pair.src_lang));
  }

  get url() {
    return `/project/${this.key}`;
  }

  get valid() {
    if (this._valid) return this._valid;

    const { title, org, brief, contacts, reqs, billing } = this

    const valid = {
      title: Boolean(title.trim()),
      org: Boolean(org.trim()),
      brief: Boolean(brief.trim()),
      contacts: Boolean(!contacts.isEmpty() && contacts.every(c => c.valid)),
      reqs: !reqs.isEmpty(),
      billing: billing.valid
    }

    let all = true
    for (const key in valid) {
      if (typeof (valid[key]) === "object") {
        all = all && valid[key]['all']
      } else {
        all = all && valid[key]
      }
    }

    valid.all = all

    this._valid = valid

    return this._valid;
  }

  lang_pairs_for_profile_type(profile_type) {
    return this.reqs.filter(r => r.profile_type === profile_type).map(r => r.lang_pair)
  }

  team_url(user_key) {
    return `/project/${this.key}/team/${user_key}`
  }

  static fromJS(jsObj) {
    const ret = Object.assign({}, jsObj);

    if (jsObj.startline) {
      Object.assign(ret, {
        startline: Date.parse(jsObj.startline)
      });
    }

    if (jsObj.deadline) {
      Object.assign(ret, {
        deadline: Date.parse(jsObj.deadline)
      });
    }

    ret.created = Date.parse(jsObj.created);

    if (jsObj.team) {
      ret.team = jsObj.team
        .reduce((result, member) => {
          return result.set(member.user_key, TeamMember.fromJS(member));
        }, Map());
    }

    if (ret.contacts) {
      ret.contacts = List(ret.contacts.map(c => Contact.fromJS(c)));
    }

    if (jsObj.billing) ret.billing = BillingRecord.fromJS(jsObj.billing)

    if (jsObj.reqs) ret.reqs = OrderedSet(jsObj.reqs.map(r => Requirement().fromJS(r)))

    return new Project(ret);
  }

  get startline_moment() {
    return this.startline && moment(this.startline)
  }

  get deadline_moment() {
    return this.deadline && moment(this.deadline)
  }
}

export const ProjectContext = React.createContext()