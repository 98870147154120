import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { Map } from 'immutable';
import store from 'store';

import Language from 'models/language';
import { ActionTypes, actions } from 'actions/lang';


class LangStore extends ImmutableReduceStore {
  constructor() {
    super();
    actions.fetch_langs();
  }

  getInitialState() {
    const stored = store.get('langStoreState');
    if (stored) {
      return LangStore.mapFromLangs(Object.values(stored));
    }
    return Map();
  }

  langs(keys) {
    const langs = {};
    keys.forEach(k => {
      langs[k] = this.lang(k);
    });

    return Map(langs);
  }

  lang(lang_key) {
    return this.getState().get(lang_key) || new Language();
  }

  get allLangs() {
    return this.getState();
  }

  static mapFromLangs(langs) {
    const records = {};
    langs.forEach(lang => {
      records[lang.key] = new Language(lang);
    });

    return Map(records).sortBy(l => l.language);
  }

  reduce(state, action) { // eslint-disable-line class-methods-use-this
    let newState = state;
    switch (action.type) {
      case ActionTypes.LANGS_LIST_UPDATE: {
        newState = LangStore.mapFromLangs(action.langs);
        break;
      }
      default:
    }

    store.set('langStoreState', newState.toJS());
    return newState;
  }
}

export default new LangStore();
