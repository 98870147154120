import { OrderedMap, Record, Set, Map } from 'immutable';

import { PaymentsRecord } from './payments_record'

import Enum from 'enum';

import { SignupType } from 'models/user'

export { PaymentsRecord }

export const SettingGroup = new Enum({
  EMAIL: 0,
  FUTURE: 1,
  SIGNUP: 2,
  PAYMENTS: 3
});


export const SpecificService = new Enum({
  TRANSCRIPTION: 0,
  LIVE_TRANSLATION: 1,
  FDC_SURVEYOR: 2,
  FDC_QUANT: 3,
  FDC_SUPER: 4,
  DATA_ENTRY: 5,
  REPORT_WRITING: 6,
  GRANT_WRITING: 7,
  DATA_ANALYSIS: 8
});

export const ServiceTypes = new Enum({
  TRANSLATION: "Translation",
  TRANSCRIPTION: "Transcription",
  WRITING_AND_COMM: 'Writing & Communications',
  // M_AND_E: 'Monitoring & Evaluations',
  OTHER: 'Other',
})

export class SignupDataRecord extends Record({
  name: '', signup_type: null,
  serv: Set(),
  org: '', brief: '', urgent: false, details: '',// Hire
  bio: '', serv_details: Map(), languages: '',// Work
  email: '',
  phone: '',
  referral: '',
  agreed: false,
}) {
  static fromJS(obj) {
    if ('signup_type' in obj)
      obj.signup_type = SignupType.get(obj.signup_type)

    if ('serv' in obj)
      obj.serv = Set(obj.serv.map(v => ServiceTypes.get(v)))

    if ('serv_details' in obj)
      obj.serv_details = Map().withMutations(m => {
        Object.keys(obj.serv_details).map(k => m.set(ServiceTypes.get(k), obj.serv_details[k]))
      })

    return new SignupDataRecord(obj)
  }
}

export const FutureType = new Enum([
  'FutureSpecificServices', 'FutureOtherServices'
])

export class Setting extends Record({
  description: null,
  type: null,
  value: null,
  group: null,
}) {
  static fromJS(jsObj) {
    jsObj.group = SettingGroup.get(jsObj.group);
    switch (jsObj.group) {
      case SettingGroup.FUTURE:
        jsObj.type = FutureType.get(jsObj.type);
        if (jsObj.type === FutureType.FutureSpecificServices) {
          jsObj.value = Set(jsObj.value.map(v => SpecificService.get(v)))
        }
        break;
      case SettingGroup.SIGNUP:
        jsObj.value = SignupDataRecord.fromJS(jsObj.value || {})
        break;
      case SettingGroup.PAYMENTS:
        jsObj.value = PaymentsRecord.fromJS(jsObj.value || {})
        break;
      default:
    }

    return new Setting(jsObj);
  }
}

export function SettingsFromJS(jsObjs) {
  return jsObjs.reduce((o, jsObj) => {
    const s = Setting.fromJS(jsObj)
    return o.set(s.type, s);
  }, OrderedMap());
}

export default Setting;