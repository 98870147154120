import ImmutableReduceStore from 'stores/immutabe-reduce-store';
import { Record, Map, List, Set } from 'immutable';

import { fromJS, ContractState } from 'models/contract';

import { actions, ActionTypes } from 'actions/contract';
import * as user from 'actions/user';


class ContractStore extends ImmutableReduceStore {
  getInitialState() {
    return new Record({
      my: List(),
      contracts: Map(),
      contracts_fetched: Set(),
      project_contracts_fetched: Set()
    })();
  }

  get_by_key(contract_key) {
    if (!this._state.contracts_fetched.has(contract_key)) {
      actions.get(contract_key);
    }

    return this._state.contracts.get(contract_key);
  }


  my() {
    return this.map_from_contract_keys(this._state.my);
  }

  pending_contracts(project_key) {
    const ret = this.project_contracts(project_key);
    return ret.filter(contr => ContractState.PROPOSED === contr.state);
  }

  contracts_for_profile_user(project_key, user_key) {
    return this.project_contracts(project_key).filter(contr => contr.provider_user_key === user_key);
  }

  project_contracts(project_key) {
    if (!project_key) return Map();

    if (!this._state.project_contracts_fetched.has(project_key)) {
      actions.get_for_project(project_key);
    }

    return this._state.contracts.filter(contract => contract.project_key === project_key);
  }

  map_from_contract_keys(keys) {
    const ret = {};
    const allContracts = this._state.contracts;

    keys.forEach(key => {
      const contr = allContracts.get(key);
      ret[contr.key] = contr;
    });

    return Map(ret);
  }

  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.UPDATE_CONTRACTS: {
        const contracts = action.contracts.map(jsObj => fromJS(jsObj));
        const contract_keys = List(action.contracts.map(contr => contr.key));
        return state.withMutations(mState => {
          contracts.forEach(contr => mState.setIn(['contracts', contr.key], contr));
          mState.update('contracts_fetched', s => s.merge(contract_keys));
        });
      }

      case ActionTypes.MY_CONTRACTS: {
        const contracts = action.contracts.map(jsObj => fromJS(jsObj));
        const contract_keys = List(action.contracts.map(contr => contr.key));
        return state.withMutations(mState => {
          contracts.forEach(contr => mState.setIn(['contracts', contr.key], contr));
          mState.set('my', contract_keys);
          mState.update('contracts_fetched', s => s.merge(contract_keys));
        });
      }

      case ActionTypes.CONTRACTS_FOR_PROJECT: {
        const contracts = action.contracts.map(jsObj => fromJS(jsObj));
        return state.withMutations(mState => {
          contracts.forEach(contr => {
            mState.setIn(['contracts', contr.key], contr);
            mState.update('contracts_fetched', s => s.add(contr.key));
          });

          mState.update('project_contracts_fetched', s => s.add(action.project_key));
        });
      }

      case user.ActionTypes.USER_STATUS: {
        if (!action.logged_in) {
          return this.getInitialState();
        }

        return state;
      }
      default:
        return state;
    }
  }
}

window.contractStore = new ContractStore();
export default window.contractStore;
