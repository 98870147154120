import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { Map, Set, Record } from 'immutable';

import { ActionTypes, actions } from 'actions/project';

import * as user from 'actions/user';

import Project from 'models/project';


class ProjectStore extends ImmutableReduceStore {
  getInitialState() {
    return new Record({
      projects: Map(),
      my: Set(),
      my_fetched: false,
    })();
  }

  project(project_key) {
    if (!project_key) return new Project();

    const project = this._state.projects.get(project_key);
    if (!project) {
      actions.fetch_project(project_key);
    }

    return project || new Project();
  }

  my() {
    if (!this._state.my_fetched) {
      actions.my();
    }
    return this._state.projects.filter(p => this._state.my.has(p.key));
  }

  project_with_member(user_key) {
    return this._state.projects.find(proj => proj.team.has(user_key))
      || new Project();
  }


  static projectsFromDict(dict) {
    const ret = {};
    Object.keys(dict).forEach(k => {
      ret[k] = Project.fromJS(dict[k]);
    });
    return Map(ret);
  }

  static stateWithProjectsJS(state, projects_js) {
    return state.withMutations(s => {
      Object.values(projects_js).forEach(proj_js => {
        const project = Project.fromJS(proj_js);
        s.setIn(['projects', project.key], project);
      });
    });
  }

  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.MY_PROJECTS: {
        return ProjectStore.stateWithProjectsJS(state, action.projects)
          .set('my', Set(Object.keys(action.projects)))
          .set('my_fetched', true);
      }

      case ActionTypes.CREATE_PROJECT: {
        return ProjectStore.stateWithProjectsJS(state, [action.project])
          .update('my', s => s.add(action.project.key));
      }

      case ActionTypes.UPDATE_PROJECT: {
        return ProjectStore.stateWithProjectsJS(state, [action.project]);
      }

      case ActionTypes.DELETE_PROJECT: {
        return state.deleteIn(['projects', action.project_key]);
      }

      case user.ActionTypes.USER_STATUS: {
        if (action.logged_in) {
          this.my();
          return state;
        }
        return this.getInitialState();
      }

      default:
        return state;
    }
  }
}

const projectStore = new ProjectStore();
window.projectStore = projectStore;
export default projectStore;
