import { LangPairContract } from './contract'
import ContractType from './contract_type'
import { TranscriberAssignment } from 'models/assignment';
import { WorkUnit } from 'models/cost';

export default class TranscriberContract extends LangPairContract({
  contract_type: ContractType.TRANSCRIBER,
  verb: 'Transcribe',
  work_unit: WorkUnit.minute
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    return new TranscriberContract(sup);
  }

  get ASSIGNMENT_CLASS() {
    return TranscriberAssignment
  }
}