import React, { useState } from 'react';

import useFluxStore from 'flux-hooks';

import {
  Glyphicon,
  Panel
} from 'react-bootstrap';

import moment from 'moment';

import ActivityStore from 'stores/activityStore';
import { actions } from 'actions/activity';

export default function HistoryPanel(props) {

  const { type, activity_key } = props
  const [expanded, setExpanded] = useState(props.expanded)
  const activity = useFluxStore(ActivityStore, (p, store) => store.get(type, activity_key, true))


  const toggleHistory = opened => {
    if (opened) actions.fetch(type, activity_key);
    setExpanded(opened);
  }

  return <Panel header="History" onToggle={toggleHistory} defaultExpanded={Boolean(activity)}>
    <Panel.Heading>
      <Panel.Title toggle>
        History <Glyphicon className="pull-right inherit-line-height" glyph={expanded ? 'menu-up' : 'menu-down'} />
      </Panel.Title>
    </Panel.Heading>
    <Panel.Collapse>
      <Panel.Body>
        {activity ?
          activity.isEmpty() ?
            <span>Nothing in history</span>
            :
            activity.toArray().map(act => (
              <div key={act.key}>
                <span style={{ fontWeight: 'bold' }}>{moment(act.created).format('lll')} </span>
                <blockquote className="h6" style={{ margin: 0 }}>
                  <span className="text-primary">{act.user_displayname} </span>
                  <span style={{ whiteSpace: 'pre-wrap' }}>
                    {act.log}
                  </span>
                </blockquote>
              </div>))
          :
          (<span>
            Fetching History...
          </span>)
        }
      </Panel.Body>
    </Panel.Collapse>
  </Panel>


}