import React, { Fragment } from 'react';

import {
  Button, ButtonGroup,
  ControlLabel,
  FormControl, FormGroup,
  InputGroup,
  Glyphicon,
  Label,
  ListGroup,
  ListGroupItem,
  Col, Row,
} from 'react-bootstrap';


import FormGroupCols, { valid_to_state } from 'components/form_group_cols';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import { AttachmentItemType, AttachmentItem } from 'models/attachment';
import { List } from 'immutable';
import filesize from 'filesize';

export function AttachmentEditFormGroupCol({ obj, list_key, onChange, max_file_size = 1e8, ...props }) {


  function onFileSelect(event) {
    const { files } = event.target;

    if (!files || files.length === 0 || !files[0]) {
      return
    }

    const file_list = List(Object.values(files).map(f => AttachmentItem.fromFile(f)))

    const oversized_files = file_list.filter(f => f.upload.file.size > max_file_size)
    if (!oversized_files.isEmpty()) {
      return window.alert(`File cannot be larger than ${max_file_size / 1e6}MB:\n` + oversized_files.map(f => f.name).join('\n'));
    }

    onChange(obj.update(list_key, l => l.push(...file_list)))
  }

  function onAddLink() {
    onChange(obj.update(list_key, l => l.push(AttachmentItem.fromJS({ item_type: AttachmentItemType.LINK }))))
  }

  function onNameChange(k) {
    return function (e) {
      onChange(obj.updateIn([list_key, k], att => att.set('name', e.target.value)))
    }
  }

  function onURLChange(k) {
    return function (e) {
      onChange(obj.updateIn([list_key, k], att => att.set('url', e.target.value)))
    }
  }

  function onDelete(k) {
    return function () {
      (attachments.get(k).item_id) ?
        onChange(obj.update(list_key, l => l.setIn([k, 'delete'], true)))
        :
        onChange(obj.update(list_key, l => l.remove(k, true)))
    }
  }

  const attachments = obj.get(list_key)

  return <FormGroupCols rightColClassName="text-center" {...props} >
    <ListGroup>
      {attachments.map((att, k) => {
        if (att.delete) return <Fragment key={k} />
        const name_label = att.item_type === AttachmentItemType.UPLOAD ? 'Filename' : 'Link Text'

        return <ListGroupItem key={k}>
          <InputGroup>
            <InputGroup.Addon>
              <Glyphicon glyph={att.item_type.key.toLowerCase()} />
            </InputGroup.Addon>
            <InputGroup style={{ width: '100%' }}>
              <InputGroup.Addon>
                {name_label}
              </InputGroup.Addon>
              <FormGroup validationState={valid_to_state(att.valid.name)}>
                <FormControl
                  type="text"
                  placeholder={name_label}
                  style={{ textAlign: 'center' }}
                  onChange={onNameChange(k)}
                  value={att.name || ''}
                />
              </FormGroup>
            </InputGroup>

            {att.item_type === AttachmentItemType.LINK &&
              <InputGroup style={{ width: '100%' }}>
                <InputGroup.Addon>
                  URL
                </InputGroup.Addon>
                <FormGroup validationState={valid_to_state(att.valid.url)}>
                  <FormControl
                    type="text"
                    placeholder="Paste URL Here"
                    style={{ textAlign: 'center' }}
                    onChange={onURLChange(k)}
                    value={att.url || ''}
                  />
                </FormGroup>
              </InputGroup>
            }

            <InputGroup.Addon>
              <Button onClick={onDelete(k)} bsSize="small">
                <Glyphicon glyph="trash" />
              </Button>
            </InputGroup.Addon>
          </InputGroup>

        </ListGroupItem>
      }
      )}
    </ListGroup>
    <ButtonGroup>
      <Button onClick={onAddLink}>
        <Glyphicon glyph="link" /> Add Link
      </Button>

      <label className="btn btn-default btn-file">
        <Glyphicon glyph="upload" /> Upload File<FormControl
          multiple={true}
          type="file"
          onChange={onFileSelect}
          label="File"
          style={{ display: 'none' }}
        />
      </label>
    </ButtonGroup>
  </FormGroupCols>
}

export function AttachmentDisplaySmallRow({ obj, list_key, label }) {
  if (obj[list_key].isEmpty()) return <></>

  return <Row>
    <Col componentClass={ControlLabel} sm={2}>{label}</Col>
    <Col sm={10}>
      <AttachmentSpanWithBr {...{ obj, list_key }} />
    </Col>
  </Row>
}

const AttachmentListGroup = ({ obj, list_key }) =>
  <ListGroup className="text-left">
    {obj[list_key].map((att, k) =>
      <ListGroupItem key={k}>
        <InputGroup>
          <InputGroup.Addon>
            <Glyphicon glyph={att.item_type === AttachmentItemType.LINK ? 'link' : 'file'} />
          </InputGroup.Addon>
          <span>
            <a href={att.url} target="_blank" rel="noopener noreferrer" >
              {att.name}
            </a>
            {att.item_type === AttachmentItemType.UPLOAD &&
              <a href={att.url} target="_blank" rel="noopener noreferrer" download={att.name}>
                <Glyphicon glyph="download-alt space-left" />
              </a>
            }
          </span>
        </InputGroup>

      </ListGroupItem>
    )}
  </ListGroup>


const AttachmentSpanWithBr = ({ obj, list_key }) => <>
  {obj[list_key].map((att, k) =>
    <span key={k}>
      <Glyphicon glyph={att.item_type === AttachmentItemType.LINK ? 'link' : 'file'} className="space-right" />
      <span>
        <a href={att.url} target="_blank" rel="noopener noreferrer" >
          {att.name}
        </a>
        {att.item_type === AttachmentItemType.UPLOAD &&
          <>
            <Label className="space-left" bsSize="xs">{filesize(att.upload?.file_size)}</Label>
            <a href={att.url} target="_blank" rel="noopener noreferrer" download={att.name}>
              <Glyphicon glyph="download-alt space-left" />
            </a>
          </>
        }
      </span>
      <br />
    </span>
  )}
</>

export function AttachmentDisplayFormGroupCols({ obj, list_key, dense, ...props }) {

  if (obj[list_key].isEmpty()) return <></>
  const DispComponent = !!dense ? AttachmentSpanWithBr : AttachmentListGroup
  return <FormGroupCols {...props} >
    <DispComponent {...{ obj, list_key }} />
  </FormGroupCols>
}