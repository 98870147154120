import React, { Fragment } from 'react';

import {
  Glyphicon
} from 'react-bootstrap';

const LangPairRateSpan = ({
  lang_pair_rate,
  muted,
  hide_rate,
  usual_lang_pair_rate,
  highlight_trg,
  rate_unit,
  children }) => {


  function AdditionalText() {
    function RateFragment() {
      if (lang_pair_rate.has_rate) return <Fragment>at ₹<strong>{lang_pair_rate.rate_display}</strong>/{rate_unit}</Fragment>;
      return '';
    }

    if (usual_lang_pair_rate && usual_lang_pair_rate.rate) {
      return (
        <span> <RateFragment /> (Usually: ₹{usual_lang_pair_rate.rate_display}/{rate_unit})</span>
      );
    }

    return (
      <span> <RateFragment /></span>
    );
  }

  return (
    <span className={muted ? 'text-muted' : ''}>
      {lang_pair_rate.lang_pair.src_display} <Glyphicon glyph="arrow-right" /> {
        highlight_trg ?
          <strong>{lang_pair_rate.lang_pair.trg_display}</strong>
          :
          lang_pair_rate.lang_pair.trg_display
      }
      {!hide_rate &&
        <AdditionalText />
      }
      {children}
    </span>
  );
}

export default LangPairRateSpan