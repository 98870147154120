import React, { useState, useRef } from 'react';

import {
  Breadcrumb,
  ButtonGroup, Button,
  Checkbox,
  Glyphicon,
  FormGroup,
  FormControl, InputGroup,
  Jumbotron,
  Radio,

} from 'react-bootstrap';

import Textarea from "react-textarea-autosize";

import {
  LinkContainer
} from 'react-router-bootstrap';

import Enum from 'enum';

import UserStore from 'stores/userStore';
import BillingStore from 'stores/billingStore'

import { actions } from 'actions/billing';

import FormGroupCols, { FormGroupSimple, valid_to_state } from 'components/form_group_cols';
import { DistrictDisplay, PostalCodeStateOptions, default_choice } from 'components/pincode'

import { StateMatchInfo } from 'components/gst'

import useFluxStore from 'flux-hooks';
import { analytics } from 'segment';


export const DisplayMode = new Enum([
  'EDIT',
  'DISPLAY',
  'SAVING'
]);

export const PaymentsDisplay = ({ record, banking_only = false }) => {

  const ifsc_details = useFluxStore(BillingStore, (prevState, store) => store.ifsc(record.bank_ifsc))

  return <div className="text-center">
    {!banking_only &&
      <React.Fragment>
        <FormGroupCols label="Details for Invoice">
          <h5>{record.name}</h5>
          <Glyphicon glyph="phone-alt" /> {record.phone}<br />
          <span className="multiline">{record.address}</span><br />
          <DistrictDisplay addr={record} /><br />
          PAN: {record.pan}
        </FormGroupCols>
        <FormGroupCols label="GST">
          <p>Registered: <strong>{record.has_gst ? "Yes" : "No"}</strong></p>
          {record.has_gst === true &&
            <React.Fragment>
              <p>GSTIN: {record.gst}</p>
              Registered in State: {record.gst_state_display}<br />
              <StateMatchInfo record={record} />
            </React.Fragment>
          }
        </FormGroupCols>
      </React.Fragment>
    }
    <FormGroupCols label="Banking">
      {ifsc_details &&
        <p>
          {ifsc_details['BANK']}, {ifsc_details['BRANCH']}<br />
          {ifsc_details['CITY']}, {ifsc_details['STATE']}
        </p>
      }
      <strong>IFSC Code:</strong> {record.bank_ifsc}<br />
      <strong>Name:</strong> {record.bank_account_holder_name}<br />
      <strong>Type:</strong> {record.bank_account_type}<br />
      <strong>Account Number:</strong> {record.bank_account}
    </FormGroupCols>
    {!banking_only &&
      <FormGroupCols label="Vendor Agreement">
        <p>
          <strong>You {record.payments_agreement ? <span>have</span> : <span>have not</span>} agreed</strong> with the <a target='_blank' href="/legal/vendor.html">
            vendor agreement
          </a>
        </p>
      </FormGroupCols>
    }
  </div>
}


export default function PaymentSettings({ inProfileEdit = false }) {

  const [loading, setLoading] = useState(false)
  const server_data = useFluxStore(UserStore, (p, store) => store.get_settings_payments())

  const [mode, setMode] = useState(server_data.payments_agreement ? DisplayMode.DISPLAY : DisplayMode.EDIT)

  const [data, setData] = useState(server_data)

  const ifsc_details = useFluxStore(BillingStore, (p, store) => store.ifsc(data.bank_ifsc), [data.bank_ifsc])

  const last_server_data = useRef(server_data)

  if (!server_data.equals(last_server_data.current)) {
    last_server_data.current = server_data
    setData(server_data)
    setMode(server_data.payments_agreement ? DisplayMode.DISPLAY : DisplayMode.EDIT)
  }

  const onSave = () => {
    setLoading(true)
    actions.update_payment_settings(data)
      .catch(err => alert.showAlert(err.reason))
      .then(() => {
        setLoading(false);
        setMode(DisplayMode.DISPLAY)
        analytics.track('Profile Payments Updated', { category: 'profile' })
      });
  }

  const onCancel = () => {
    setData(server_data)
    setMode(DisplayMode.DISPLAY)
  }


  const onDataChange = e => {

    const key = e.target.getAttribute('data-key')
    let value = e.target.value
    switch (e.target.getAttribute('data-transform')) {
      case "uppercase":
        value = value.toUpperCase()
        break
      default:
    }

    switch (e.target.type) {
      case "checkbox":
        value = e.target.checked
        break
      case "radio":
        if (e.target.value === "yes") {
          value = true
        } else if (e.target.value === "no") {
          value = false
        }
        break
      default:
    }

    setData(data.set(key, value))
  }

  const setPostalCode = e => {
    const [postal_code, dist, state] = default_choice(e.target.value)
    setData(data.withMutations(d => {
      d.setIn(['postal_code'], postal_code)
      d.setIn(['dist'], dist)
      d.setIn(['state'], state)
    })
    )
  }

  const setDistState = e => {
    const [dist, state] = e.target.value.split(',')
    setData(data.withMutations(d => {
      d.setIn(['dist'], dist)
      d.setIn(['state'], state)
    })
    )
  }

  const onClickEdit = () => {
    setMode(DisplayMode.EDIT)
  }


  const d = data

  if (d === undefined) return <h4>Loading...</h4>;


  const changes = !server_data.equals(d)

  const ActionButtons = () =>
    <ButtonGroup>
      <Button
        disabled={!changes || !d.valid.all || loading}
        bsStyle={d.valid.all ? "success" : undefined}
        onClick={onSave}
      >
        {changes ? "Save" : "No Changes"}
      </Button>
      <Button
        disabled={!server_data.payments_agreement || loading}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </ButtonGroup>

  return <React.Fragment>
    {!inProfileEdit &&
      <React.Fragment>
        <Breadcrumb className="ellipsis">
          <LinkContainer to="/">
            <Breadcrumb.Item>
              Home
            </Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to='/settings'>
            <Breadcrumb.Item>
              Settings
            </Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            Payments
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="text-center">Payments</h1>
      </React.Fragment>
    }

    {server_data.payments_agreement &&
      <div className="pull-right">
        {mode === DisplayMode.DISPLAY ?
          <ButtonGroup>
            <Button disabled={loading} bsStyle="warning" onClick={onClickEdit}>Edit</Button>
          </ButtonGroup>
          :
          <ActionButtons />
        }
      </div>
    }

    <Jumbotron id="payments-settings" className="text-center">
      {!server_data.payments_agreement &&
        <p>Your profile will be visible only <strong>once you complete this form.</strong></p>
      }
      {mode === DisplayMode.DISPLAY ?
        <PaymentsDisplay record={d} />
        :
        <React.Fragment>
          <h4>Details for Invoice</h4>
          <FormGroupCols label='Name' valid={d.valid.name}>
            <FormControl
              value={d.name}
              onChange={onDataChange}
              data-key="name"
              autoComplete="name"
              type="text"
              placeholder="Full Name"
            />
          </FormGroupCols>
          <FormGroupCols label='Phone' valid={d.valid.phone}>
            <FormControl
              value={d.phone}
              onChange={onDataChange}
              data-key="phone"
              autoComplete="tel"
              type="Phone"
              placeholder="Phone Number"
            />
          </FormGroupCols>
          <FormGroupCols label='Address' valid={d.valid.address}>
            <Textarea
              className="form-control"
              style={{ width: '100%' }}
              placeholder="Address"
              onChange={onDataChange}
              data-key="address"
              autoComplete="street-address"
              value={d.address}
            />
          </FormGroupCols>
          <FormGroupCols label='Postal Code' valid={d.valid.postal_code && d.valid.dist_state}>
            <FormControl
              value={d.postal_code}
              onChange={setPostalCode}
              data-key="postal_code"
              autoComplete="postal-code"
              type="text"
              placeholder="Postal Code"
            />

            <FormControl
              data-key="dist_and_state"
              value={d.dist_state}
              onChange={setDistState}
              componentClass="select"
              placeholder="Select District and State">
              <PostalCodeStateOptions addr={d} />
            </FormControl>
          </FormGroupCols>
          <FormGroupCols label='PAN' valid={d.valid.pan}>
            <FormControl
              value={d.pan}
              onChange={onDataChange}
              data-key="pan"
              data-transform="uppercase"
              type="text"
              placeholder="PAN Card Number"
            />
          </FormGroupCols>

          <FormGroupCols label="GST">
            <FormGroup validationState={valid_to_state(d.valid.has_gst)}>
              <InputGroup>
                <InputGroup.Addon>Registered:</InputGroup.Addon>
                <Radio value="yes"
                  data-key="has_gst"
                  className="space-right"
                  checked={d.has_gst === true} onChange={onDataChange}
                  type="radio" inline
                >
                  Yes
                </Radio>
                <Radio value="no"
                  data-key="has_gst"
                  checked={d.has_gst === false} onChange={onDataChange}
                  inline type="radio"
                >
                  No
                </Radio>
              </InputGroup>
            </FormGroup>
            {d.has_gst === true &&
              <FormGroup validationState={valid_to_state(d.valid.gst.all)}>
                <InputGroup>
                  <InputGroup.Addon>GSTIN:</InputGroup.Addon>
                  <FormControl
                    value={d.gst}
                    onChange={onDataChange}
                    data-key="gst"
                    data-transform="uppercase"
                    type="text"
                    placeholder="GST Identification Number"
                  />
                </InputGroup>
                {d.valid.gst.number &&
                  <h5 className="text-center">
                    Registered in State: {d.gst_state_display}
                  </h5>
                }
                <StateMatchInfo record={d} />
              </FormGroup>
            }
          </FormGroupCols>
          <hr />
          <h4>Banking Details</h4>

          <FormGroupCols label='IFSC Code' valid={d.valid.bank_ifsc}>
            <FormControl value={d.bank_ifsc}
              data-key="bank_ifsc"
              data-transform="uppercase"
              onChange={onDataChange} type="text" placeholder="Bank IFSC Code"
            />
            {ifsc_details &&
              <h5>
                <br />
                {ifsc_details['BANK']}, {ifsc_details['BRANCH']}<br />
                {ifsc_details['CITY']}, {ifsc_details['STATE']}
              </h5>
            }
          </FormGroupCols>
          <FormGroupCols label='Account'>
            <FormGroupSimple label="Account Holder Name" valid={d.valid.bank_account.name}>
              <FormControl value={d.bank_account_holder_name}
                data-key="bank_account_holder_name"
                onChange={onDataChange}
                type="text"
                placeholder="Account Holder's Name"
              />
            </FormGroupSimple>
            <FormGroupSimple valid={d.valid.bank_account.type}
              label='Type:' label_props={{ className: "space-right" }} >
              <Radio value='SAVINGS'
                data-key="bank_account_type"
                checked={d.bank_account_type === 'SAVINGS'} onChange={onDataChange}
                inline type="radio"
              >
                Savings
              </Radio>
              <Radio value='CURRENT'
                data-key="bank_account_type"
                className="space-right"
                checked={d.bank_account_type === 'CURRENT'} onChange={onDataChange}
                type="radio" inline
              >
                Current
              </Radio>
            </FormGroupSimple>
            <FormGroupSimple label="Account Number" valid={d.valid.bank_account.number}>
              <FormControl value={d.bank_account}
                data-key="bank_account"
                onChange={onDataChange}
                type="text"
                placeholder="Account Number"
              />
            </FormGroupSimple>
            <FormGroupSimple valid={d.valid.bank_account.number_confirm}>
              <FormControl value={d.bank_account_confirm}
                data-key="bank_account_confirm"
                onChange={onDataChange}
                type="text"
                placeholder="Confirm Account Number"
              />
            </FormGroupSimple>
          </FormGroupCols>
          <hr />
          <FormGroupCols label="Vendor Agreement" valid={d.valid.payments_agreement}>
            <Checkbox
              data-key="payments_agreement"
              onChange={onDataChange}
              checked={d.payments_agreement}>
              I acknowledge that I have read, understood and agree
              to the <a target='_blank' href="/legal/vendor.html">Vendor Agreement</a>
            </Checkbox>
          </FormGroupCols>
          <hr />
          <ActionButtons />
        </React.Fragment>
      }
    </Jumbotron>
  </React.Fragment>

}