import React from 'react'

import {
  Pager
} from 'react-bootstrap';

export default function EnumDisplay({ enums, enum_to_string = e => e.toString(), className = '', label = '', ...props }) {
  if (!enums || enums.isEmpty()) return <React.Fragment />
  return <><Pager className={`h6 strong no-margin no-click-pager ${className}`} {...props}>
    {label && <li className="space-right">{label}</li>}
    {enums.map(e => <Pager.Item style={{ margin: '0.2em' }} key={e}>
      {enum_to_string(e)}
    </Pager.Item>
    )}
  </Pager></>
}