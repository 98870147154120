import React, { useState, useRef } from 'react';

import useFluxStore from 'flux-hooks';

import {
  Breadcrumb,
  Button,
  ButtonGroup,
  FormControl,
  Glyphicon,
  HelpBlock,
  Jumbotron,
} from 'react-bootstrap';

import {
  LinkContainer
} from 'react-router-bootstrap';


import Enum from 'enum';

import AlertDismissable from 'components/alert_dismissable';
import ContactListEdit from 'components/contact/list_edit';
import FormGroupCols from 'components/form_group_cols';

import UserInfo from 'components/user';
import UserPhoto from 'components/user/photo';
import ResumeLink from 'components/user/resume_link';

import { actions } from 'actions/user';

import UserStore from 'stores/userStore';
import { UserType } from 'models/user';

import { ContactsFromUserDetails } from 'models/contact'
import { DomainTypes, DomainTypeString } from 'models/personal_info';
import EnumSelector from 'components/enum_selector';


export const DisplayMode = new Enum([
  'EDIT',
  'DISPLAY',
  'SAVING'
]);

export default function PersonalInfo({ inProfileEdit = false }) {


  const alert = useRef()

  const [user, signup_settings] = useFluxStore(UserStore, (p, store) =>
    [store.user, store.get_settings_signup()])

  const init_info = user.info.set('contacts', ContactsFromUserDetails(user.info.contacts, user.email, signup_settings))

  const [mode, setMode] = useState(DisplayMode.DISPLAY)
  const [info, setInfo] = useState(init_info)

  const force_contacts = inProfileEdit || user.user_type === UserType.PROVIDER

  if (force_contacts && user.info.contacts.isEmpty() && mode !== DisplayMode.EDIT) {
    setMode(DisplayMode.EDIT)
  }

  function onClickEdit() {
    setMode(DisplayMode.EDIT)
  }

  function onClickCancel() {
    setMode(DisplayMode.DISPLAY)
    setInfo(init_info)
  }

  const onNameChange = ({ target }) => {
    setInfo(i => i.set('name', target.value))
  }


  function onDomainsChange(domains) {
    setInfo(i => i.set('domains', domains))
  }

  function onContactsUpdate(contacts) {
    setInfo(i => i.set('contacts', contacts))
  }

  function onPhotoFileSelect(event) {
    const { files } = event.target;

    if (!files || files.length === 0 || !files[0]) {
      return onPhotoRemove()
    }

    if (files[0].size > 1e7) {
      return alert.current.showAlert("Photo cannot be larger than 10 MB");
    }

    const reader = new FileReader();
    reader.onload = e => {
      setInfo(i => i.set_photo(files[0], e.target.result))
    }

    reader.readAsDataURL(files[0]);
  }

  function onPhotoChangeCancel() {
    setInfo(info => info.withMutations(i => {
      i.set('photo', user.info.photo);
      i.set('photo_update', false)
    }))
  }

  function onPhotoRemove() {
    setInfo(i => i.remove_photo())
  }

  function onResumeFileSelect(event) {
    const { files } = event.target;

    if (!files || files.length === 0 || !files[0]) {
      return onResumeRemove()
    }

    if (files[0].size > 1e7) {
      return alert.current.showAlert("Resume cannot be larger than 10 MB");
    }

    setInfo(i => i.set_resume(files[0]))
  }

  function onResumeRemove() {
    setInfo(i => i.remove_resume())
  }

  function onResumeChangeCancel() {
    setInfo(
      info => info.withMutations(i => {
        i.set('resume', user.info.resume);
        i.set('resume_update', false)
      })
    )
  }

  function clickSaveChanges() {
    alert.current.hideAlert();

    setMode(DisplayMode.SAVING);

    return actions.update_info(info, user.info)
      .then(() => {
        setMode(DisplayMode.DISPLAY)
      })
      .catch(err => {
        setMode(DisplayMode.EDIT)
        alert.current.showAlert(err.reason);
      });
  }


  const first_time_contacts = !user.info.valid.contacts
  const save_button_disabled = force_contacts ?
    (!info.valid.all || !info.valid.contacts) : !info.valid.all;

  return <React.Fragment>
    {!inProfileEdit &&
      <React.Fragment>
        <Breadcrumb className="ellipsis">
          <LinkContainer to="/">
            <Breadcrumb.Item>
              Home
            </Breadcrumb.Item>
          </LinkContainer>
          <LinkContainer to='/settings'>
            <Breadcrumb.Item>
              Settings
            </Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            Personal Info
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="text-center">Personal Info</h1>
      </React.Fragment>
    }
    {!(inProfileEdit && first_time_contacts) &&

      <div style={{ float: 'right' }}>
        {mode === DisplayMode.DISPLAY ?
          <ButtonGroup>
            <Button bsStyle="warning" onClick={onClickEdit}>Edit</Button>
          </ButtonGroup>
          :
          <ButtonGroup>
            {mode === DisplayMode.EDIT &&
              <React.Fragment>
                <Button
                  bsStyle="success"
                  disabled={save_button_disabled}
                  onClick={clickSaveChanges}
                >
                  Save Changes</Button>
                {!(force_contacts && first_time_contacts) &&
                  <Button onClick={onClickCancel}>Cancel</Button>
                }
              </React.Fragment>
            }
            {mode === DisplayMode.SAVING &&
              <Button
                bsStyle="success"
                disabled={true}
              >
                Saving...</Button>
            }
          </ButtonGroup>
        }
      </div>
    }
    <Jumbotron className="text-center">
      <br />
      {mode === DisplayMode.DISPLAY ?
        <UserInfo info={user.info} user_key={user.key} />
        :
        <React.Fragment>
          <AlertDismissable errorTitle="Error" ref={alert} />
          <React.Fragment>
            <FormGroupCols label="Photo" controlId="photoUploadControl">
              <UserPhoto info={info} /><br />
              <ButtonGroup bsSize="small" style={{ marginTop: '20px' }}>
                <label htmlFor="photoUploadControl" className="btn btn-default btn-primary btn-file">
                  Upload<FormControl
                    id="photoUploadControl"
                    type="file"
                    accept='image/*'
                    onChange={onPhotoFileSelect}
                    label="File"
                    style={{ display: 'none' }}
                  />
                </label>
                {info.photo && !info.photo_update &&
                  <Button onClick={onPhotoRemove}>Remove</Button>
                }
                {info.photo_update &&
                  <Button onClick={onPhotoChangeCancel}>Cancel</Button>
                }
              </ButtonGroup>
            </FormGroupCols>
            <FormGroupCols label="Display Name">
              <HelpBlock>
                Update your display name to your <strong>full name</strong> so the community knows who you are.</HelpBlock>
              <FormControl
                value={info.name}
                onChange={onNameChange}
                autoComplete="name"
                type="text"
                placeholder="Display Name"
              />
            </FormGroupCols>
            <FormGroupCols label='Contact'
              valid={
                force_contacts ?
                  !info.contacts.isEmpty() && info.valid.contacts
                  :
                  info.valid.contacts
              }>
              <ContactListEdit
                contacts={info.contacts}
                onContactsUpdate={onContactsUpdate}
              />
            </FormGroupCols>
            {user.user_type !== UserType.CLIENT &&
              <>
                <FormGroupCols label='Resume' controlId="resumeUploadControl" info="Upload your resume for clients to download here">
                  {info.resume &&
                    <ResumeLink info={info} user_key={user.key} />
                  }
                  <ButtonGroup style={{ marginTop: '20px' }}>
                    <label htmlFor="resumeUploadControl" className="btn btn-default btn-file">
                      <Glyphicon glyph="upload" /> Upload Resume<FormControl
                        id="resumeUploadControl"
                        type="file"
                        accept='application/msword, application/pdf, .doc, .docx, .pdf'
                        onChange={onResumeFileSelect}
                        label="File"
                        style={{ display: 'none' }}
                      />
                    </label>
                    {info.resume && !info.resume_update &&
                      <Button onClick={onResumeRemove}>Remove</Button>
                    }
                    {info.resume_update &&
                      <Button onClick={onResumeChangeCancel}>Cancel</Button>
                    }
                  </ButtonGroup>
                </FormGroupCols>

                <FormGroupCols label='Domains'>
                  <HelpBlock>
                    Select your domains of experience
                  </HelpBlock>
                  <EnumSelector
                    EnumToSelect={DomainTypes}
                    enum_to_string={DomainTypeString}
                    selected={info.domains}
                    onChange={onDomainsChange}
                  />
                </FormGroupCols>
              </>
            }
            {inProfileEdit && first_time_contacts &&
              <FormGroupCols>
                <br />
                <Button
                  bsStyle="success"
                  disabled={save_button_disabled}
                  onClick={clickSaveChanges}
                >
                  Save Changes</Button>
              </FormGroupCols>
            }
          </React.Fragment>
        </React.Fragment>
      }
    </Jumbotron>
  </React.Fragment>


}
