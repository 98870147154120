// import * as analytics from 'analytics.js'
import config from 'config';

// Create a queue, but don't obliterate an existing one!
// var analytics = window.analytics = window.analytics || [];
const analytics = {
  page: (...props) => window.analytics.page(...props),
  track: (...props) => window.analytics.track(...props),
  identify: (...props) => window.analytics.identify(...props)
}

export { analytics }

window.addEventListener('DOMContentLoaded', function () {
  window.analytics.load(config.SEGMENT_WRITE_KEY);
})