import * as Immutable from 'immutable';

import LangStore from 'stores/langStore';

export class LanguagePair extends Immutable.Record({
  src: undefined,
  trg: undefined
}) {
  static fromJS(jsObj) {
    return new LanguagePair(jsObj);
  }

  get complete() {
    return this.get('src') && this.get('trg')
  }

  get key() {
    return `${this.get('src')}->${this.get('trg')}`;
  }

  get src_lang() {
    return LangStore.lang(this.get('src'));
  }

  get trg_lang() {
    return LangStore.lang(this.get('trg'));
  }

  get src_display() {
    return this.src_lang.display;
  }

  get trg_display() {
    return this.trg_lang.display;
  }

  get display() {
    return `${this.src_display} → ${this.trg_display}`
  }
}


export class LanguagePairRate extends Immutable.Record({
  lang_pair: new LanguagePair(),
  rate: undefined
}) {
  get key() {
    return this.get('lang_pair').key;
  }

  get rate_display() {
    return this.has_rate ? this.get('rate') : ''
  }

  get has_rate() {
    return Number.isFinite(parseFloat(this.rate));
  }

  support_src(lang_key) {
    return this.lang_pair.src === lang_key;
  }

  static fromJS(jsObj) {
    const ret = jsObj;
    ret.lang_pair = new LanguagePair(ret.lang_pair);
    return new LanguagePairRate(ret);
  }
}
