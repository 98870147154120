import Enum from 'enum';
import { Record } from 'immutable';


export const CostType = new Enum({
  CONTRACT_RATE: 0,
  FIXED: 1
})

export const WorkUnit = new Enum({
  word: 0,
  minute: 1
})

export default class Cost extends Record({
  cost_type: undefined,
  rate: undefined,
  work: undefined,
  work_unit: undefined,
  total: undefined
}) {
  static fromJS(jsObj) {
    jsObj.cost_type = CostType.get(jsObj.cost_type)
    jsObj.work_unit = WorkUnit.get(jsObj.work_unit)
    return new Cost(jsObj);
  }

  calculate_total() {
    switch (this.cost_type) {
      case CostType.CONTRACT_RATE:
        const num_work = parseInt(this.work);
        return (Number.isFinite(this.rate) && Number.isFinite(num_work)) ? this.rate * num_work : undefined
      case CostType.FIXED:
        const num_rate = parseFloat(this.rate)
        return Number.isFinite(num_rate) ? num_rate : undefined
      default:
        throw Error('Unknown CostType ' + this.cost_type)
    }
  }

  size_mutation(work) {
    return this.withMutations(c => {
      c.work = work;
      c.total = c.calculate_total();
    })
  }

  fixed_amount_mutation(amount) {
    return this.withMutations(c => {
      c.rate = amount
      c.total = c.calculate_total()
    })
  }

  get valid() {
    switch (this.cost_type) {
      case CostType.CONTRACT_RATE:
        return !!(
          this.cost_type
          && this.work_unit
          // We have to account for rate, work or total to be 0
          && Number.isFinite(this.rate)
          && Number.isFinite(parseInt(this.work)))
          && Number.isFinite(this.total)
      case CostType.FIXED:
        return !!(this.cost_type
          && isFinite(parseFloat(this.rate))
          && Number.isFinite(this.total))
      default:
        throw Error('Unknown CostType ' + this.cost_type)
    }
  }
}