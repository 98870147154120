import Enum from 'enum';

import dispatcher from 'dispatcher';

import axios from 'axios'
import fetchPlus from 'fetchPlus';

import * as contract from 'actions/contract';
import * as assignment from 'actions/assignment';
import * as user from 'actions/user';

export const ActionTypes = new Enum([
  'MY_PROFILE',
  'UPDATE_PROFILE',
  'GET_PROFILES',
  'GET_PROFILES_A',
  'PROJECT_CANDIDATE_PROFILES'
]);

export const actions = {
  get_profiles(profile_keys) {
    return fetchPlus({
      url: '/api/profile/get_profiles',
      body_obj: {
        profile_keys
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.GET_PROFILES,
          profiles: json.profiles
        });
      }
    });
  },

  get_profiles_a(q) {
    return fetchPlus({
      url: '/api/profile/get_profiles_a',
      body_obj: {
        q
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.GET_PROFILES_A,
          profiles_a: json.profiles_a,
          q
        });
      }
    });
  },

  my() {
    return fetchPlus({
      url: '/api/profile/my',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.MY_PROFILE,
          profiles: json.profiles
        });

        dispatcher.dispatch({
          type: contract.ActionTypes.MY_CONTRACTS,
          contracts: json.contracts
        });

        dispatcher.dispatch({
          type: assignment.ActionTypes.MY_ASSIGNMENTS,
          assignments: json.assignments
        });
      }
    }).catch(() => {
    });
  },


  project_candidate_profiles(project_key) {
    return fetchPlus({
      url: `/api/profile/project_candidates/${project_key}`,
      method: 'GET',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.PROJECT_CANDIDATE_PROFILES,
          profiles: json.profiles,
          project_key
        });
      }
    });
  },

  create_profile(profile, onUploadProgress) {
    const data = new FormData();
    data.append('profile', JSON.stringify(profile))

    return new Promise(resolve => {
      if (profile.portfolio.some(pi => pi.upload?.file)) {
        profile.portfolio.map((pi, k) => (pi.upload?.file) && data.append(`att_${k}`, pi.upload.file))
        fetchPlus({
          url: `/api/profile/create_profile`
        }).then(resp => resolve(resp['url']))
      } else {
        resolve('/api/profile/create_profile')
      }
    }).then(url => axios.request({
      url,
      method: 'POST',
      data,
      onUploadProgress
    }).then(({ data }) => {
      user.dispatchUserFromSuccessfulResponse(data)
    }))
  },

  update_profile(profile, onUploadProgress) {
    const data = new FormData();
    data.append('profile', JSON.stringify(profile))

    return new Promise(resolve => {
      if (profile.portfolio.some(att => att.has_upload)) {
        profile.portfolio.map((pi, k) => pi.has_upload && data.append(`att_${k}`, pi.upload.file))
        fetchPlus({
          url: `/api/profile/update_profile/${profile.key}`
        }).then(resp => resolve(resp['url']))
      } else {
        resolve('/api/profile/update_profile')
      }
    }).then(url => axios.request({
      url,
      method: 'POST',
      data,
      onUploadProgress
    }).then(({ data }) => {
      dispatcher.dispatch({
        type: ActionTypes.UPDATE_PROFILE,
        profile: data.profile
      })
    }))
  }
};
