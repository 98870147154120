// We use this because from notifications that involve an invoice
// the user will hit /invoice/:invoice_uid
// based on user information, we either forward them just to the project
// or if it's the client, we forward them to the team member's billing page in the project

import React, { useContext } from 'react';

import InvoiceStore from 'stores/invoiceStore';
import { UserContext } from 'stores/userStore';

import useFluxStore from 'flux-hooks';
import { UserType } from 'models/user';

import { Redirect } from 'react-router-dom';

export default function InvoiceRedirect({ match }) {

  const { invoice_uid } = match.params;

  const invoice = useFluxStore(InvoiceStore, (p, store) => store.get_by_uid(invoice_uid))
  const { user } = useContext(UserContext)

  if (!invoice) {
    // Wait for InvoiceStore to fetch the contract
    return <h1>Redirecting...</h1>;
  }

  if (user.user_type === UserType.PROVIDER) {
    return <Redirect to={`/project/${invoice.project_key}#billing/${invoice.state.key}`} />;
  } else {
    return <Redirect to={`/project/${invoice.project_key}/team/${invoice.provider_user_key}/#billing/${invoice.state.key}`} />
  }
}
