import React, { Fragment } from 'react';

import { ContactType } from 'models/contact';

import ContactGlyph from './glyph';

const ContactSpan = ({
  contact,
  no_href = false
}) => {
  const span_href = () => {
    switch (contact.contact_type) {
      case ContactType.EMAIL:
        return `mailto:${contact.value}`;
      case ContactType.MOBILE:
        return `tel:${contact.value}`;
      default:
        throw Error('Unkonwn ContactType');
    }
  };
  const InnerText = () => (
    <Fragment>
      <ContactGlyph contact_type={contact.contact_type} /> {contact.value}
    </Fragment>
  );
  return (
    <span>
      {no_href ?
        <InnerText />
        :
        <a href={span_href()}><InnerText /></a>
      }
    </span>
  );
};

export default ContactSpan;
