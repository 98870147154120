import { LangPairProfile } from './profile'
import ProfileType from './profile_type.js'

export default class TranslatorProfile extends LangPairProfile({
  profile_type: ProfileType.TRANSLATOR,
  rate_unit: "word"
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    return new TranslatorProfile(sup);
  }
}