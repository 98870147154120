import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { Record, Map, Set } from 'immutable';

import { fromJS } from 'models/assignment';

import { actions, ActionTypes } from 'actions/assignment';
import * as user from 'actions/user';

// import diff from 'immutablediff';
// import moment from 'moment';

class AssignmentStore extends ImmutableReduceStore {
  getInitialState() {
    return new Record({
      my: Set(),
      doc_fetched: Set(),
      contract_fetched: Set(),
      project_fetched: Set(),
      assignments: Map(),
    })();
  }

  get_by_key(assignment_key) {
    return this._state.assignments.get(assignment_key);
  }

  get_project_assignments(project_key) {
    if (!project_key) return Map()
    return this.get_multi_projects_assignments([project_key])
  }

  get_multi_projects_assignments(project_keys) {
    const project_keys_set = Set(project_keys)
    if (project_keys_set.isEmpty()) return Map();

    const remaining_projects = project_keys_set.subtract(this._state.project_fetched);

    remaining_projects.map(actions.get_project_assignments)

    return this._state.assignments.filter(a => project_keys_set.has(a.project_key));
  }

  get_contracts_assignments(contract_keys) {
    if (contract_keys.isEmpty()) return Map();

    const remaining_contracts = contract_keys.toSet().subtract(this._state.contract_fetched);

    if (remaining_contracts.size > 0) {
      actions.get_contracts_assignments(remaining_contracts);
    }

    return this._state.assignments.filter(a => contract_keys.has(a.contract_key));
  }

  get_document_multi_assignments(docs) {
    const doc_keys = docs.map(d => d.key).toSet();

    const remaining_doc_keys = doc_keys.toSet().subtract(this._state.doc_fetched);
    if (remaining_doc_keys.size > 0) {
      actions.get_documents_assignments(remaining_doc_keys);
    }

    return this._state.assignments
      .filter(a => doc_keys.has(a.document_key))
      .groupBy(a => a.document_key);
  }

  get_document_assignments(doc) {
    if (doc.isEmpty()) return Map();

    if (!this._state.doc_fetched.has(doc.key)) {
      actions.get_documents_assignments([doc.key]);
    }
    return this._state.assignments.filter(a => a.document_key === doc.key);
  }

  my() {
    return this._state.assignments.filter(a => this._state.my.has(a.key));
  }

  static set_assignments_in_mutable_state(m_state, assgs) {
    assgs.reduce((s, a_js) => {
      const a = fromJS(a_js);
      return s.setIn(['assignments', a.key], a)
    }, m_state)
  }

  areEqual(one, two) {
    return one === two
  }

  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.UPDATE_ASSIGNMENTS: {
        return state.withMutations(s => {
          AssignmentStore.set_assignments_in_mutable_state(s, action.assignments)
        });
      }

      case ActionTypes.DOCUMENTS_ASSIGNMENTS_FETCHED: {
        return state.withMutations(s => {
          AssignmentStore.set_assignments_in_mutable_state(s, action.assignments)
          s.update('doc_fetched', fetched => fetched.union(action.doc_keys));
        });
      }

      case ActionTypes.CONTRACTS_ASSIGNMENTS_FETCHED: {
        return state.withMutations(s => {
          AssignmentStore.set_assignments_in_mutable_state(s, action.assignments)
          s.update('contract_fetched', fetched => fetched.union(action.contract_keys));
        });
      }

      case ActionTypes.PROJECT_ASSIGNMENTS_FETCHED: {
        return state.withMutations(s => {
          AssignmentStore.set_assignments_in_mutable_state(s, action.assignments)
          s.update('project_fetched', fetched => fetched.add(action.project_key));
        });
      }

      case ActionTypes.MY_ASSIGNMENTS: {
        return state.withMutations(s => {
          AssignmentStore.set_assignments_in_mutable_state(s, action.assignments)
          s.set('my', Set(action.assignments.map(a => a['key'])));
        });
      }

      case user.ActionTypes.USER_STATUS: {
        if (!action.logged_in) {
          return this.getInitialState();
        }
        return state;
      }
      default:
        return state;
    }
  }
}

window.assignmentStore = new AssignmentStore();
export default window.assignmentStore;
