import React from 'react';


import {
  Breadcrumb,
  Checkbox,
  Form,
  Jumbotron,
  FormGroup,
} from 'react-bootstrap';

import {
  LinkContainer
} from 'react-router-bootstrap';

import UserStore from 'stores/userStore';

import { actions } from 'actions/user';
import { SettingGroup } from 'models/settings';

import useFluxStore from 'flux-hooks'

export default function EmailSettings() {

  const [settings, email] = useFluxStore(UserStore, (p, store) =>
    [store.settings.groupBy(s => s.group).get(SettingGroup.EMAIL),
    store.user.email])

  const onChange = (type, { target }) => {
    actions.update_settings([[type, target.checked]]);
  }

  if (!settings) return <div />;

  return <React.Fragment>
    <Breadcrumb className="ellipsis">
      <LinkContainer to="/">
        <Breadcrumb.Item>
          Home
        </Breadcrumb.Item>
      </LinkContainer>
      <LinkContainer to='/settings'>
        <Breadcrumb.Item>
          Settings
        </Breadcrumb.Item>
      </LinkContainer>
      <Breadcrumb.Item active>
        Email
      </Breadcrumb.Item>
    </Breadcrumb>
    <h1 className="text-center">Email</h1>
    <Jumbotron className="text-center">
      <p>{email}</p>

      <Form horizontal>
        {settings.valueSeq().map(s => (
          <FormGroup key={s.type} controlId={"formSetting" + s.type}>
            <Checkbox
              id={s.type}
              checked={s.value}
              onChange={e => onChange(s.type, e)}
            >
              {s.description}
            </Checkbox>
          </FormGroup>
        ))}
      </Form>


    </Jumbotron>
  </React.Fragment>
}
