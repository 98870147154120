import React from 'react';


import {
  Nav, NavItem,
  Well,
} from 'react-bootstrap';


const EnumSelector = ({ EnumToSelect, enum_to_string = e => e.toString(), selected, onChange }) => {
  function onToggle(e) {
    if (selected.includes(e)) {
      onChange(selected.delete(e))
    } else {
      onChange(selected.add(e))
    }
  }

  return <Well className="text-center">
    <Nav bsStyle="pills">
      {EnumToSelect.enums.map(e => <NavItem key={e}
        className={selected.includes(e) && 'active'}
        onSelect={() => onToggle(e)}
        style={{ margin: '2px' }}
      >
        {enum_to_string(e)}
      </NavItem>)}
    </Nav>
  </Well>
}

export default EnumSelector