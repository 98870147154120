import dispatcher from 'dispatcher';
import Enum from 'enum';

export const FETCH_EVENT = new Enum(['SERVER_CHANGE'])

const fetching = {};
window.fieldscope_fetching = fetching;

const fetchPlus = function ({
  url,
  body_obj,
  credentials = 'include',
  method = 'GET',
  debounce = true,
  options,
  success
}) {
  const opts = Object.assign(
    {
      credentials,
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body_obj),
    },
    options
  );

  if (opts.headers === 'remove') {
    delete opts.headers;
  }

  const fetch_uuid = `${url} ${method} ${JSON.stringify(body_obj)}`;
  if (debounce) {
    if (fetch_uuid in fetching) {
      return fetching[fetch_uuid];
    }
  }


  const fetchPromise = fetch(url, opts)
    .then(response => {
      if (debounce) {
        delete fetching[fetch_uuid];
      }

      if (!response.ok) {
        return {
          success: false,
          reason: response.statusText
        }
      }

      dispatcher.dispatch({
        type: FETCH_EVENT.SERVER_CHANGE,
        version: response.headers.get('fieldscope-server')
      })

      return response.json();

    }).then(json => {
      if (json.success) {
        if (success) success(json);
      } else {
        const err = new Error(`${json?.reason} at ${url}`)
        err.reason = json?.reason
        throw err
      }
      return json;
    })

  if (debounce) {
    fetching[fetch_uuid] = fetchPromise;
  }

  return fetchPromise;
}

export function postFile(url, file) {
  const data = new FormData();
  if (file)
    data.append('data', file);

  return fetchPlus({
    url,
    method: 'POST',
    options: {
      body: data,
      headers: 'remove'
    },
  });
}

export default fetchPlus;