import React from 'react';

import { HIWModal } from 'components/hiw'

export const EasySteps = () => <React.Fragment>
  <ol>
    <p>
      <strong>Four Easy Steps:</strong>
      <li><strong>Create your profile</strong> and await a callback request from
                interested clients.</li>
      <li><strong>Discuss </strong> payments, timelines and scope of work before accepting a contract on Fieldscope.</li>
      <li><strong>Accept the contract</strong>, now the client can start assigning work to you.</li>
      <li><strong>Upload</strong> your work and send your assignment for review</li>
    </p>
  </ol>
</React.Fragment>

export class ProviderHIWModal extends HIWModal {
  EasySteps = EasySteps
  TabDescription = () => <React.Fragment>
    <h5>Four tabs to streamline all your work:</h5>
    <p>
      <span className="text-primary">PROJECT</span>: Introduces you to the project<br />
      <span className="text-primary">CONTRACTS</span>: Agreement on rate, scope and other terms <br />
      <span className="text-primary">WORKSPACE</span>: Manage tasks &amp; upload files<br />
      <span className="text-primary">BILLING</span>: Keeps track of completed work &amp; payments<br />
    </p>
  </React.Fragment>
}