import React from 'react';

import {
  Col,
  ControlLabel,
  FormGroup
} from 'react-bootstrap';

import InfoPopover from 'components/info_popover';

export const valid_to_state = valid => {
  switch (valid) {
    case true:
      return 'success'
    case false:
      return 'warning'
    default:
      return valid
  }
}


export const FormGroupSimple = ({ valid, label, label_props, children }) =>
  <React.Fragment>
    <FormGroup
      className="form-group-cols-right"
      controlId={label}
      validationState={valid_to_state(valid)}
    >
      {label &&
        <ControlLabel {...label_props}>{label}</ControlLabel>
      }
      {children}
    </FormGroup>
  </React.Fragment>

const FormGroupCols = (
  { label,
    labelStyle,
    labelClass = "h5",
    info,
    children,
    controlId,
    valid,
    offsetSize,
    rightColClassName,
    ...other }
) => {
  controlId = controlId || label;

  let noLabelCols;
  switch (offsetSize) {
    case "sm":
      noLabelCols = [2, 8, 2]
      break
    case "off":
      noLabelCols = [0, 12, 0]
      break
    default:
      noLabelCols = [3, 9, 0]
  }


  return <FormGroup
    className="flex-center-768"
    controlId={controlId}
    validationState={valid_to_state(valid)}
    {...other}
  >
    {label ?
      <React.Fragment>
        <Col sm={3} className={`${labelClass} text-right-768`}>
          <ControlLabel style={labelStyle}>{label}</ControlLabel>
          {info &&
            <InfoPopover controlId={controlId}>
              {info}
            </InfoPopover>
          }
        </Col>
        <Col sm={9} className={`form-group-cols-right ${rightColClassName}`}>
          <blockquote style={{ margin: 0 }}>
            {children}
          </blockquote>
        </Col>
      </React.Fragment>
      :
      <React.Fragment>
        <Col sm={noLabelCols[0]} />
        <Col sm={noLabelCols[1]} className={`form-group-cols-right ${rightColClassName}`}>
          {children}
        </Col>
        <Col sm={noLabelCols[2]} />
      </React.Fragment>
    }
  </FormGroup>
};

export default FormGroupCols;