import React from 'react';

import {
  Glyphicon,
  HelpBlock,
  OverlayTrigger, Popover,
} from 'react-bootstrap';

const InfoPopover = ({ controlId, title, children }) => (
  <OverlayTrigger trigger="click" rootClose placement="right" overlay={
    <Popover id={controlId} title={title}><HelpBlock>{children}</HelpBlock></Popover>
  }>
    <Glyphicon glyph="info-sign text-info space-left" />
  </OverlayTrigger>
);

export default InfoPopover;