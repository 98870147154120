import { Record, List } from 'immutable';
import Enum from 'enum';

import { LanguagePair } from 'models/language_pair';

import ContractStore from 'stores/contractStore';
import DocumentStore from 'stores/documentStore';
import { UploadedFile } from 'models/uploaded_file';
import Cost from 'models/cost';

import LangStore from 'stores/langStore';

export const AssignmentState = new Enum({
  ASSIGNED: 0,
  IN_PROGRESS: 1,
  REVIEW: 2,
  COMPLETED: 5,
  CANCELLED: 8,
  REJECTED: 9
});

export const AssignmentType = new Enum({
  BASE: 0,
  TRANSLATOR: 1,
  TRANSCRIBER: 2,
  WRITER: 3
});

export function fromJS(d) {
  switch (AssignmentType.get(d.assignment_type)) {
    case AssignmentType.TRANSLATOR:
      return TranslatorAssignment.fromJS(d)
    case AssignmentType.TRANSCRIBER:
      return TranscriberAssignment.fromJS(d)
    case AssignmentType.WRITER:
      return WriterAssignment.fromJS(d)
    default:
      throw Error("Unknown Assignment Type", d)
  }
}

const Assignment = defaultValues => class extends Record({
  key: undefined,
  document_key: undefined,
  contract_key: undefined,
  profile_key: undefined,
  user_key: undefined,
  project_key: undefined,
  state: AssignmentState.ASSIGNED,
  cost: new Cost(),
  reject_reason: undefined,
  created: undefined,
  updated: undefined,
  ...defaultValues
}) {
  static supJS(ret) {
    ret.state = AssignmentState.get(ret.state);
    ret.assignment_type = AssignmentType.get(ret.assignment_type);
    ret.cost = Cost.fromJS(ret.cost);
    ret.created = Date.parse(ret.created);
    ret.updated = Date.parse(ret.updated);
    return ret;
  }

  get req_key() {
    throw Error("Not implemented")
  }

  get verb() {
    throw Error("Not implemented")
  }

  get description() {
    throw Error("Not implemented")
  }

  get contract() {
    return ContractStore.get_by_key(this.contract_key);
  }

  get document() {
    return DocumentStore.document(this.document_key);
  }

  get active() {
    return this.state <= AssignmentState.COMPLETED;
  }

  get can_archive() {
    return this.state >= AssignmentState.COMPLETED;
  }

  get url() {
    return `/document/${this.document_key}`;
  }

  get ready_for_new() {
    return !!(this.document_key && this.contract_key && this.cost.valid)
  }
};

export class AssignmentUpload extends Record({
  name: undefined,
  uploaded: undefined,
  uploader: undefined,
  src_file: new UploadedFile()
}) {
  static fromJS(jsObj) {
    return new AssignmentUpload({
      name: jsObj.name,
      uploaded: Date.parse(jsObj.uploaded),
      src_file: new UploadedFile(jsObj.src_file)
    });
  }

  get key() {
    return this.src_file.key;
  }

  get download_url() {
    return `/api/assg/dl/${this.src_file.key}/${this.name}`
  }
}

export class TranslatorAssignment extends Assignment({
  lang_pair: new LanguagePair(),
  assignment_type: AssignmentType.TRANSLATOR,
  wordcount: undefined,
  translations: List()
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    sup.lang_pair = new LanguagePair(jsObj.lang_pair);
    sup.translations = List(jsObj.translations.map(t => AssignmentUpload.fromJS(t)));
    return new TranslatorAssignment(sup);
  }

  get req_key() {
    return this.lang_pair.key
  }

  get verb() {
    return "Translate"
  }

  get description() {
    return `${this.verb}: ${this.lang_pair.display}`
  }

  get cost_size_name() {
    return this.constructor.cost_size_name
  }

  static get cost_size_name() {
    return "wordcount"
  }

  get size() {
    return this.wordcount
  }

  set size(val) {
    this.wordcount = val
  }

}

export class TranscriberAssignment extends Assignment({
  lang_pair: new LanguagePair(),
  assignment_type: AssignmentType.TRANSCRIBER,
  duration: undefined,
  translations: List(),
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    sup.lang_pair = new LanguagePair(jsObj.lang_pair);
    sup.translations = List(jsObj.translations.map(t => AssignmentUpload.fromJS(t)));
    return new TranscriberAssignment(sup);
  }

  get req_key() {
    return this.lang_pair.key
  }

  get verb() {
    return "Transcribe"
  }

  get description() {
    return `${this.verb}: ${this.lang_pair.display}`
  }

  get cost_size_name() {
    return this.constructor.cost_size_name
  }

  static get cost_size_name() {
    return "duration"
  }

  get size() {
    return this.duration
  }

  set size(val) {
    this.duration = val
  }


}

export class WriterAssignment extends Assignment({
  src: undefined,
  assignment_type: AssignmentType.WRITER,
  wordcount: undefined,
  translations: List(),
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);

    sup.translations = List(jsObj.translations.map(t => AssignmentUpload.fromJS(t)));
    return new WriterAssignment(sup);
  }

  get req_key() {
    return this.src
  }

  get lang() {
    return LangStore.lang(this.src);
  }

  get verb() {
    return "Write"
  }

  get description() {
    return `${this.verb}: ${this.lang.display}`
  }

  get cost_size_name() {
    return this.constructor.cost_size_name
  }

  static get cost_size_name() {
    return "wordcount"
  }

}

export function assignmentCounters(assignments) {
  return assignments.reduce(
    (o, a) => {
      if (a.state <= AssignmentState.CANCELLED) {
        o.count += 1;
        if (a.state === AssignmentState.ASSIGNED) {
          o.assigned += 1;
        } else if (a.state === AssignmentState.IN_PROGRESS) {
          o.in_progress += 1;
        } else if (a.state === AssignmentState.REVIEW) {
          o.review += 1;
        } else if (a.state === AssignmentState.COMPLETED) {
          o.complete += 1;
        }
      }
      return o;
    },
    {
      count: 0,
      assigned: 0,
      in_progress: 0,
      review: 0,
      complete: 0
    }
  );
}
