import React from 'react';

import useFluxStore from 'flux-hooks';

import UserStore from 'stores/userStore';

import SetPassword from './set_password';
import Wait from './wait';

function Auth() {

  const user = useFluxStore(UserStore, (p, userStore) => userStore.user)
  const { active,  password_is_set, email_verified } = user
  
  
    if (!active) {
      return <Wait/>
    } else {
      if (!email_verified) {
        return <Wait verify_email_only/>
      }
      if(!password_is_set) {
        return <SetPassword/>
      }
    }
    return <h3>Something's gone wrong, please email us <a href="mail:support@fieldscope.in">support@fieldscope.in</a></h3>
}

export default Auth;
