import Enum from 'enum';
import { LangProfile } from './profile'
import ProfileType from './profile_type.js'
import { Set } from 'immutable';


export const WriterContentType = new Enum({
  ACADEMIC: 0,
  COPYWRITING: 1,
  CURRICULUM: 2,
  PROPOSAL: 3,
  REPORT: 4
});

export default class WriterProfile extends LangProfile({
  profile_type: ProfileType.WRITER,
  content_types: Set(),
  rate_unit: "word"
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    sup.content_types = Set(jsObj.content_types.map(ct => WriterContentType.get(ct)))
    return new WriterProfile(sup);
  }

  match_req(req) {
    if (req.profile_type !== this.profile_type) return false
    return this.lang_rates.map(lr => lr.src).includes(req.src)
      && !this.content_types.intersect(req.content_types).isEmpty()
  }

  get valid() {
    if (this._valid) return this._valid;
    this._valid = {
      content_types: !this.content_types.isEmpty(),
      ...super.valid
    }

    return this._valid;
  }
}