import Enum from 'enum';

import dispatcher from 'dispatcher';
import ReasonError from 'reason_error';
import fetchPlus, { postFile } from 'fetchPlus';

import diff from 'immutablediff';


export const ActionTypes = new Enum([
  'USER_STATUS',
  'USER_SETTINGS'
]);

export const dispatchUserFromSuccessfulResponse = ({ user, profiles }) => {
  // We expect our server to respond with the user object
  // on requests that result in an authenticated user
  dispatcher.dispatch({
    type: ActionTypes.USER_STATUS,
    user,
    profiles,
    logged_in: true
  });
};

export const actions = {

  user_status() {
    return fetchPlus({
      url: '/api/auth/user.json',
      options: {
        redirect: 'manual'
      },
      success: json => {
        dispatchUserFromSuccessfulResponse(json);
      }
    }).catch(e => {
      if (e.reason === "Not Authenticated") {
        dispatcher.dispatch({
          type: ActionTypes.USER_STATUS,
          logged_in: false
        });
      } else {
        throw e
      }
    });
  },


  logout() {
    return fetchPlus({
      url: '/api/auth/logout.json',
      success: () => {
        dispatcher.dispatch({
          type: ActionTypes.USER_STATUS,
          logged_in: false
        });
      }
    });
  },

  forgot_password(email, recaptcha_response) {
    return fetchPlus({
      url: '/api/auth/forgot_password.json',
      method: 'POST',
      body_obj: {
        email,
        recaptcha_response
      }
    });
  },

  set_password(password, confirm_password) {
    return fetchPlus({
      url: '/api/auth/set_password.json',
      method: 'POST',
      body_obj: {
        password,
        confirm_password
      },
      success: json => {
        dispatchUserFromSuccessfulResponse(json);
      }
    });
  },

  update_info(info, info_o) {
    const info_no_files = info.remove_files();
    const info_o_no_files = info_o.remove_files();
    const promises = []
    if (!diff(info_no_files, info_o_no_files).isEmpty()) {
      promises.push(fetchPlus({
        url: '/api/auth/update_info',
        method: 'POST',
        body_obj: {
          info: info_no_files
        }
      }));
    }

    if (info.photo_update) {
      promises.push(postFile(
        `/api/auth/update_info_photo`,
        info.photo && info.photo.file
      ));
    }


    if (info.resume_update) {
      promises.push(postFile(
        `/api/auth/update_info_resume`,
        info.resume && info.resume.file
      ));
    }

    if (promises.length === 0) {
      return Promise.reject(new ReasonError("Nothing to save!"))
    }
    return Promise.all(promises).then(results => {
      return this.user_status()
    });
  },

  signup(data) {
    return fetchPlus({
      url: '/api/auth/signup',
      method: 'POST',
      body_obj: data,
      success: json => {
        dispatchUserFromSuccessfulResponse(json);
      }
    });
  },

  fetch_settings() {
    return fetchPlus({
      url: '/api/auth/settings',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.USER_SETTINGS,
          settings: json.settings
        });
      }
    });
  },

  update_settings(updates) {
    return fetchPlus({
      url: '/api/auth/update_settings',
      method: 'POST',
      body_obj: {
        updates
      },
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.USER_SETTINGS,
          settings: json.settings
        });
      }
    });
  }
};