import ImmutableReduceStore from 'stores/immutabe-reduce-store';
import { Record, Map } from 'immutable';

import { ActionTypes, actions } from 'actions/billing';
import * as user from 'actions/user';
import { isValidIFSC } from 'models/settings/payments_helper';


class BillingStore extends ImmutableReduceStore {
  getInitialState() {
    return new Record({
      ifsc_map: Map(),

    })();
  }

  ifsc(ifsc_code) {
    if (!isValidIFSC(ifsc_code)) return
    const code = ifsc_code.toUpperCase()

    if (this._state.ifsc_map.has(code))
      return this._state.ifsc_map.get(code)

    actions.ifsc(code)
  }



  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.IFSC_DETAILS: {
        return state.update('ifsc_map', ifsc_map => ifsc_map.set(action.details['IFSC'], action.details))
      }
      case user.ActionTypes.USER_STATUS: {
        if (!action.logged_in) {
          return this.getInitialState();
        }
        return state;
      }
      default:
        return state;
    }
  }
}

const bs = new BillingStore();
window.BillingStore = bs
export default bs
