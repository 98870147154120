import Enum from 'enum';
import dispatcher from 'dispatcher';
import fetchPlus from 'fetchPlus';
import querystring from 'querystring';

export const ActionTypes = new Enum([
  'UPDATE_ASSIGNMENTS',
  'MY_ASSIGNMENTS',
  'DOCUMENTS_ASSIGNMENTS_FETCHED',
  'CONTRACTS_ASSIGNMENTS_FETCHED',
  'PROJECT_ASSIGNMENTS_FETCHED'
]);

const dispatchAssignmentUpdates = ({ assignments }) => {
  dispatcher.dispatch({
    type: ActionTypes.UPDATE_ASSIGNMENTS,
    assignments
  });
};

export const actions = {
  get_documents_assignments(doc_keys) {
    fetchPlus({
      url: '/api/assg/get_for_documents',
      body_obj: {
        doc_keys
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.DOCUMENTS_ASSIGNMENTS_FETCHED,
          assignments: json.assgs,
          doc_keys
        });
      }
    });
  },

  get_contracts_assignments(contract_keys) {
    fetchPlus({
      url: '/api/assg/get_for_contracts',
      body_obj: {
        contract_keys
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.CONTRACTS_ASSIGNMENTS_FETCHED,
          assignments: json.assgs,
          contract_keys
        });
      }
    });
  },

  get_project_assignments(project_key) {
    fetchPlus({
      url: '/api/assg/get_for_project?' + querystring.stringify({ project_key }),
      method: 'GET',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.PROJECT_ASSIGNMENTS_FETCHED,
          assignments: json.assgs,
          project_key,
        });
      }
    });
  },

  create_assignment_multi(assignments) {
    return fetchPlus({
      url: '/api/assg/create_assignment_multi',
      body_obj: {
        assignments
      },
      method: 'POST',
      success: dispatchAssignmentUpdates
    });
  },

  change_assignment_state_multi(assignment_key_list, state) {
    return fetchPlus({
      url: '/api/assg/change_assignment_state_multi',
      body_obj: {
        assignment_key_list,
        state
      },
      method: 'POST',
      success: dispatchAssignmentUpdates
    });
  },

  reject_assignment(assignment_key, reject_reason) {
    return fetchPlus({
      url: '/api/assg/reject_assignment',
      body_obj: {
        assignment_key,
        reject_reason
      },
      method: 'POST',
      success: dispatchAssignmentUpdates
    })
  },

  upload_url(assignment_key) {
    return fetchPlus({
      url: '/api/assg/upload',
      debounce: false,
      method: 'POST',
      body_obj: { assignment_key }
    })
  },

  upload_complete: dispatchAssignmentUpdates,

  update_translation_upload(assignment_key, translation_upload) {
    return fetchPlus({
      url: '/api/assg/update_translation_upload',
      body_obj: {
        assignment_key,
        translation_upload
      },
      method: 'POST',
      success: dispatchAssignmentUpdates
    });
  },

  delete_translation_upload(assignment_key, translation_upload) {
    return fetchPlus({
      url: '/api/assg/delete_translation_upload',
      body_obj: {
        assignment_key,
        translation_upload
      },
      method: 'POST',
      success: dispatchAssignmentUpdates
    });
  }
};
