import React, {
  useState, forwardRef, useImperativeHandle
} from 'react';

import {
  Modal
} from 'react-bootstrap';

function ModalDismissable({ visible = false, title, children, footer, ...props }, ref) {
  const [_visible, setVisible] = useState(visible)
  useImperativeHandle(ref, () => ({
    show: () => setVisible(true),
    close: () => setVisible(false)
  }));


  return <Modal show={_visible} onHide={() => setVisible(false)} {...props}>
    <Modal.Header closeButton>
      <Modal.Title className="text-center">{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
    {
      footer &&
      <Modal.Footer>
        {footer}
      </Modal.Footer>
    }
  </Modal >
}

export default forwardRef(ModalDismissable)