import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { analytics } from 'segment'

import {
  Button,
  Jumbotron,
  Modal,
  HelpBlock
} from 'react-bootstrap';

export const UseFSDisclaimer = () => <blockquote className="multiline-left-center"><HelpBlock>Using Fieldscope for file exchange and workflow tracking ensures accountability.<br />
  We can only support work done on the platform.</HelpBlock>
</blockquote>

export class HIWModal extends Component {
  state = {
    show: false
  }

  constructor(props) {
    super();

    this.state = {
      show: props.defaultShow
    };
  }

  show = () => {
    this.setState({
      show: true
    })

    analytics.page({ title: 'How it works', path: `/modal/how_it_works/${this.constructor.name}` })
  }

  onHideIntro = () => {
    this.setState({
      show: false
    });
  }

  EasySteps = () => {
    throw Error('Undefined Easy Steps')
  }

  TabDescription = () => {
    throw Error('Undefined Tab Description')
  }

  render() {
    const { show } = this.state
    return (
      <Modal bsSize="large" show={show} onHide={this.onHideIntro}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center h1">How it works</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Jumbotron style={{ padding: '1em', margin: 0 }}>
            <this.EasySteps />
            <div className="text-center">
              <this.TabDescription />
            </div>
          </Jumbotron>
          <br />
          <UseFSDisclaimer />
        </Modal.Body>
        <Modal.Footer style={{ textAlign: "center" }}>
          <Button bsStyle="success" onClick={this.onHideIntro}>Got it!</Button>
        </Modal.Footer>
      </Modal >
    );
  }
}

HIWModal.defaultProps = {
  defaultShow: false,
};

HIWModal.propTypes = {
  defaultShow: PropTypes.bool
};