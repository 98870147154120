import Enum from 'enum';


export const AssignmentRole = new Enum({
  CREATOR: 0,
  ASSIGNEE: 1
});

export const DocumentRole = new Enum({
  OWNER: 0,
  MEMBER: 1,
  ASSIGNEE: 2,
});

export const ProjectRole = new Enum({
  OWNER: 0,
  MEMBER: 1,
  IN_CONTRACT: 9,
});
