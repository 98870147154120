import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { OrderedMap, Record } from 'immutable';

import { ActionTypes, actions } from 'actions/notification';
import { fromJS } from 'models/notification';
import * as user from 'actions/user';

class NotificationState extends Record({
  notifications: OrderedMap(),
  last_read: null,
  more: null,
  cursor: null,
  unread_count: 0
}) {
  calcuate_unread_count() {
    if (!this.last_read) return this.notifications.count();

    return this.notifications.count(n => n.created > this.last_read)
  }
}

class NotificationStore extends ImmutableReduceStore {
  getInitialState() {
    return new NotificationState();
  }

  get unread_count() {
    return this._state.unread_count;
  }

  get notifications() {
    return this._state.notifications
  }

  mark_as_read() {
    if (!this.unread_count) return;
    const top_key = this._state.notifications.first().key;
    if (this._state.last_read === top_key) return;

    actions.mark_as_read(this._state.notifications.first().key);
  }

  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.FETCHED: {
        const { notifications, cursor, last_read, more } = action;
        return state.withMutations(s => {
          notifications.forEach(n => {
            s.update('notifications', notifs => notifs.set(n.key, fromJS(n)));
          });

          s.update('notifications', notifs => notifs.sortBy(n => -n.created))

          s.set('cursor', cursor);
          if (last_read) s.set('last_read', Date.parse(last_read));
          s.set('more', more);
          s.set('unread_count', s.calcuate_unread_count());

        });
      }

      case user.ActionTypes.USER_STATUS: {
        if (!action.logged_in) return this.getInitialState();
        actions.fetch();
        return state;
      }

      default:
        return state;
    }
  }
}

window.notificationStore = new NotificationStore();
export default window.notificationStore;
