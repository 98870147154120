import React, { useRef } from 'react';


import {
  Alert,
  Button,
  Glyphicon,
  Nav,
  Navbar,
  NavItem,
  NavDropdown,
  MenuItem,
} from 'react-bootstrap';

import {
  LinkContainer
} from 'react-router-bootstrap';

import { analytics } from 'segment'

import { NavLink } from 'react-router-dom'

import useFluxStore from 'flux-hooks'

import UserStore from 'stores/userStore';
import NotificationStore from 'stores/notificationStore';
import SyncStore from 'stores/syncStore.js';

import { UserType } from 'models/user';
import { actions } from 'actions/user';

import { BellIcon } from 'components/icons';

import SupportModal from 'components/support_modal';
import { ClientHIWModal } from 'client/hiw.jsx'
import { ProviderHIWModal } from '../provider/hiw.jsx'
import logo_inverse from './logo-heavy-inverse.png';

import './topbar.css';
import { getOrigin } from 'index.js';

function Topbar() {
  const { displayname, user_type } = useFluxStore(UserStore,
    (p, store) => ({
      displayname: UserStore.user.info.name,
      user_type: UserStore.user.user_type,
    }))

  const unread_count = useFluxStore(NotificationStore, (p, store) => store.unread_count)

  const server_changed = useFluxStore(SyncStore, (p, store) => store.server_changed)

  const hiw_modal = useRef()
  const support_modal = useRef()


  const MoreLinks = () => <React.Fragment>
    <NavDropdown title="SUPPORT" id="nav-auth-dropdown">
      {user_type !== UserType.UNKNOWN &&
        <MenuItem onClick={() => hiw_modal.current.show()}>How it works</MenuItem>
      }
      <MenuItem onClick={() => support_modal.current.show()}>Contact Us</MenuItem>
      <LinkContainer to="/faq">
        <MenuItem >FAQs</MenuItem>
      </LinkContainer>
    </NavDropdown>
  </React.Fragment>;

  const UserTitle = () => (
    <span>{displayname}</span>
  );

  const NavLinks = () => <Nav pullRight>
    <MoreLinks />
    <NavDropdown active title={UserTitle()} id="nav-auth-dropdown">
      {user_type === UserType.PROVIDER &&
      <LinkContainer to="/#profile">
        <NavItem>My Profiles</NavItem>
      </LinkContainer>
        }
      <LinkContainer to="/settings/">
        <NavItem>Settings</NavItem>
      </LinkContainer>
      <NavItem onClick={async () => {
        analytics.track('Logout', { category: 'auth' })
        await actions.logout()
        window.location = getOrigin()
        }
      }>Logout</NavItem>

    </NavDropdown>
      
  </Nav >
    
  const LeftLinks = () => <Nav pullLeft>
    <NavItem onClick={()=>window.location = getOrigin()}><Glyphicon glyph="home" /></NavItem>
  </Nav>
    

  const NotifNavItem = ({ toggle = false }) => <ul className={"notif-item nav navbar-nav " + (toggle ? "navbar-toggle collapsed" : "navbar-right")} >
    <li role="presentation" className={"nav-dropdown " + (toggle ? "active" : "")}>
      <NavLink to="/#notif">
        <BellIcon count={unread_count} />
      </NavLink>
    </li>
  </ul >
    
  return <>
    <Alert id="new_version_alert" className={server_changed && 'active text-center'}>
      The website has been updated, please <Button bsSize="xsmall" onClick={() => window.location.reload(true)}>
        <Glyphicon className="space-right" glyph="refresh" />refresh
      </Button>
    </Alert>
    <SupportModal ref={support_modal} />
    {
      user_type === UserType.CLIENT &&
      <ClientHIWModal ref={hiw_modal} />
    }
    {
      user_type === UserType.PROVIDER &&
      <ProviderHIWModal ref={hiw_modal} />
    }
    <Navbar
      id="topbar"
      bsStyle={ 'inverse'}
      collapseOnSelect
      style={{ margin: '10px', zIndex: 1 }}
    >
      <Navbar.Header>
        <Navbar.Toggle />
        {/* <HelpNavItem toggle /> */}
        <NotifNavItem toggle />
      </Navbar.Header >
      <Navbar.Brand >
        <NavLink to="/">
          <span>
            <img
              alt="Fieldscope"
              src={ logo_inverse}
              width="154px"
              height="18px"
            />
          </span>
        </NavLink>
      </Navbar.Brand>
      <Navbar.Collapse>
        <LeftLinks />
        <NavLinks />
        {/* <HelpNavItem /> */}
        <NotifNavItem />
      </Navbar.Collapse>
    </Navbar >
  </>
}

export default React.memo(Topbar)