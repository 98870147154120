import * as Immutable from 'immutable';
import Enum from 'enum';
import PersonalInfo from 'models/personal_info';

export const SignupType = new Enum(['HIRE', 'WORK'])

export const UserType = new Enum({ 'UNKNOWN': 0, 'CLIENT': 1, 'PROVIDER': 2 })

export default class User extends Immutable.Record({
  active: false,
  admin: false,
  key: undefined,
  email: '',
  info: new PersonalInfo(),
  password_is_set: false,
  email_verified: false,
  user_type: UserType.UNKNOWN
}) {
  static fromJS(jsObj) {
    jsObj.info = PersonalInfo.fromJS(jsObj.info, jsObj.key);
    jsObj.user_type = UserType.get(jsObj.user_type)
    return new User(jsObj);
  }

  get ready() {
    return this.active && this.password_is_set && this.email_verified
  }
}
