import React from 'react';

const UserPhoto = ({ info, size = 200, style, ...other }) =>
  <img
    alt={info.name + "profile"}
    style={{ maxWidth: size + "px", maxHeight: size + "px", ...style }}
    className="profile-img" src={info.photo_image}
    {...other} />;

export default UserPhoto;
