import { Record } from 'immutable';

import { List } from 'immutable';
import isLength from 'validator/lib/isLength'

import { isValidPIN, isValidGST, STATE_FROM_GST } from 'models/settings/payments_helper'
import { STATES_AND_UT } from 'components/pincode/STATES_AND_UT'

class BillingRecordDetails extends Record({
  name: '',
  address: '',
  phone: '',
  postal_code: '',
  dist: '',
  state: '',
  has_gst: undefined,
  gst: '',
  international: false,
  country: ''
}) {
  get dist_state() {
    return `${this.dist},${this.state}`
  }

  get state_display() {
    return STATES_AND_UT[this.state]
  }

  get gst_state() {
    return this.has_gst && isValidGST(this.gst) ? STATE_FROM_GST(this.gst) : null
  }

  get gst_state_display() {
    return this.gst_state && STATES_AND_UT[this.gst_state]
  }

  get valid() {
    const {
      name, phone, address,
      postal_code, dist, state,
      has_gst, gst, gst_state,
      international, country,
    } = this

    const valid = {
      name: isLength(name.trim(), { min: 3 }),
      phone: isLength(phone.trim(), { min: 6 }),
      address: isLength(address.trim(), { min: 10 }),
    }
    if (international) {
      valid['country'] = country !== ''
    } else {
      valid['postal_code'] = isValidPIN(postal_code)
      valid['dist_state'] = !!dist && !!state
      valid['has_gst'] = has_gst !== undefined
      valid['gst'] = has_gst ? {
        number: isValidGST(gst),
        state_match: !state || gst_state === state
        // The abomination above:
        // Should be false only if state is defined and doesn't match
      } : {}
    }

    let all = true
    for (const key in valid) {
      if (typeof (valid[key]) === "object") {
        const sub_all = List(Object.values(valid[key])).every(v => v)
        valid[key]['all'] = sub_all
        all = all && sub_all
      } else {
        all = all && valid[key]
      }
    }

    valid.all = all || (this.enabled ? false : null)
    return valid
  }
}

export class BillingRecord extends Record({
  enabled: null,
  required: null,
  comission_rate: null,
  offline_billing: null,
  details: new BillingRecordDetails()
}) {
  static fromJS(obj) {
    obj.details = new BillingRecordDetails(obj.details)

    return new BillingRecord(obj)
  }

  get valid() {
    if (this._valid) return this._valid;

    if (!this.enabled) {
      this._valid = { all: true }
      return this._valid
    }

    return this.details.valid
  }
}