import dispatcher from 'dispatcher';
import Enum from 'enum';
import fetchPlus from 'fetchPlus';

export const ActionTypes = new Enum([
  'ACTIVITY_UPDATE',
]);

export const actions = {
  fetch(type, key, offset = 0) {
    return fetchPlus({
      url: `/api/activity/get/${type}/${key}/${offset}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.ACTIVITY_UPDATE,
          activity_type: type,
          key,
          activity: json.activity
        });
      }
    });
  }
};
