import React from 'react';

import Toggle from 'react-bootstrap-toggle';

import {
  Form,
  Checkbox,
  ListGroup, ListGroupItem,
} from 'react-bootstrap';

import Info from 'text/info/provider';
import FormGroupCols from 'components/form_group_cols';
import LangRateSpan from 'components/lang_rate_span';
import EnumDisplay from 'components/enum_display';
import { AttachmentDisplayFormGroupCols } from 'components/attachments';

export default function WriterProfileDisplay({ profile, my_profile }) {
  if (!profile) return (<div />);
  return (
    <Form className="text-center" horizontal>
      <FormGroupCols label='Description' info={my_profile && Info.profile.DESCRIPTION}>
        <span className='multiline-left-center'>{profile.description}</span>
      </FormGroupCols>

      {my_profile &&
        <FormGroupCols label='Available' info={my_profile && Info.profile.AVAILABLE}>
          <Toggle
            disabled
            on={<span className="text-center" style={{ width: '100%' }}>Available</span>}
            off={<span className="text-center" style={{ width: '100%' }}>Not Available</span>}
            size="mini"
            onstyle="success"
            offstyle="warning"
            active={profile.available}
          />
        </FormGroupCols>
      }
      <FormGroupCols label='Languages' info={my_profile && Info.profile.LANGUAGES}>
        <ListGroup>
          {profile.lang_rates.toArray().map(lr => {
            return (
              <ListGroupItem key={lr.key}>
                <p>
                  <LangRateSpan
                    lang_rate={lr}
                    rate_unit={profile.rate_unit}
                  />
                </p>
              </ListGroupItem>
            );
          })
          }
        </ListGroup>
      </FormGroupCols>
      <FormGroupCols label='Content Type' info={my_profile && Info.profile.WRITER_CONTENT_TYPE}>
        <EnumDisplay enums={profile.content_types} />
      </FormGroupCols>
      {my_profile &&
        <FormGroupCols label='Negotiable' info={my_profile && Info.profile.NEGOTIABLE}>
          <Checkbox disabled checked={profile.negotiable}>
            Rates are negotiable
          </Checkbox>
        </FormGroupCols>
      }

      <AttachmentDisplayFormGroupCols
        obj={profile}
        list_key='portfolio'
        label='Portfolio'
        info={my_profile && Info.profile.PORTFOLIO}
      />
    </Form >
  );
}
