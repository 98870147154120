import {
  Record
} from 'immutable';

import { ProjectRole } from 'models/roles';

export default class TeamMember extends Record({
  name: '',
  user_key: undefined,
  role: undefined,
  created: undefined,
}) {
  static fromJS(jsObj) {
    return new TeamMember(Object.assign(
      jsObj,
      {
        role: ProjectRole.get(jsObj.role),
        created: Date.parse(jsObj.created)
      }
    ));
  }
}