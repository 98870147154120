import React from 'react'
import { Record, Map, Set } from 'immutable';
import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { ActionTypes, actions } from 'actions/doc';

import { Document } from 'models/document';

import * as user from 'actions/user';


class DocumentStore extends ImmutableReduceStore {
  getInitialState() {
    return new Record({
      documents: Map(),
      project_fetched: Set(),
      document_fetched: Set()
    })();
  }

  project_documents(project_key) {
    if (!project_key) return Map(); // throw new Error("No Project Key");

    if (this._state.project_fetched.includes(project_key)) {
      return this._state.documents.filter(doc => doc.project_key === project_key);
    }

    actions.get_for_project(project_key)
      .catch(() => {
        console.log(`Couldn't get documents for project: ${project_key}`);
      });
    return Map();
  }

  documents(doc_keys) {
    const remaining_doc_keys = doc_keys.toSet().subtract(this._state.document_fetched);
    if (remaining_doc_keys.size > 0) {
      actions.get_documents(remaining_doc_keys);
    }

    return this._state.documents.filter(d => doc_keys.has(d.key));
  }

  document(document_key) {
    if (this._state.document_fetched.includes(document_key)) {
      return this._state.documents.get(document_key);
    }

    actions.get_document(document_key)
      .catch(() => {
        console.log(`Couldn't load documents: ${document_key}`);
      });

    return new Document();
  }

  static documentsFromDict(state, dict) {
    return state.withMutations(s => {
      Object.keys(dict).forEach(k => {
        const doc = Document.fromJS(dict[k]);
        s.setIn(['documents', k], doc);
        s.update('document_fetched', df => df.add(doc.key));
      });
    });
  }


  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.PROJECT_DOCUMENTS: {
        const s = state.update('project_fetched', pf => pf.add(action.project_key));
        return DocumentStore.documentsFromDict(s, action.documents);
      }

      case ActionTypes.UPDATE_DOCUMENTS: {
        return state.withMutations(s => {
          action.documents.forEach(doc_js => {
            const doc = Document.fromJS(doc_js);
            s.update('document_fetched', df => df.add(doc.key));
            s.setIn(['documents', doc.key], doc);
          });
        });
      }

      case user.ActionTypes.USER_STATUS: {
        if (!action.logged_in) {
          return this.getInitialState();
        }
        return state;
      }
      default:
        return state;
    }
  }
}

export const DocumentContext = React.createContext()

const documentStore = new DocumentStore();
window.documentStore = documentStore;
export default documentStore;
