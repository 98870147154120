import * as Immutable from 'immutable';


export const UploadBase = defaultValues => class extends Immutable.Record({
  key: undefined,
  file: null,
  filename: undefined,
  file_size: 0,
  uploader_name: undefined,
  uploader: undefined,
  ...defaultValues
}) {
  static fromFile(file) {
    return {
      file,
      filename: file.name,
      file_size: file.size,
    };
  }
}

export class UploadedFile extends UploadBase({}) {
  static fromFile(file) {
    return new UploadedFile(super.fromFile(file));
  }
}

export class ImageUpload extends UploadBase({
  data_url: null,
  serving_url: null
}) {
  get image() {
    return this.data_url || this.serving_url;
  }

  static fromFile(file, data_url) {
    return new ImageUpload({ ...super.fromFile(file), data_url })
  }
}

export default UploadedFile;