import React from 'react';
import ImmutableReduceStore from 'stores/immutabe-reduce-store';
import { Record, List } from 'immutable';

import { analytics } from 'segment'

import { ActionTypes, actions } from 'actions/user';
import { ActionTypes as ProjectActionTypes } from 'actions/project';
import User, { UserType } from 'models/user';
import { SettingsFromJS, PaymentsRecord, SettingGroup } from 'models/settings';

class UserStore extends ImmutableReduceStore {
  constructor() {
    super();
    actions.user_status();
  }

  getInitialState() {
    return new Record({
      logged_in: false,
      user: new User(),
      settings: false,
      auth_checked: false
    })();
  }

  get user() {
    return this._state.user;
  }

  get logged_in() {
    return this._state.logged_in;
  }

  get auth_checked() {
    return this._state.auth_checked;
  }

  get settings() {
    if (!this._state.settings) {
      actions.fetch_settings();
      return List();
    }

    return this._state.settings;
  }

  get_settings_payments() {
    const payments_group = this.settings.groupBy(s => s.group).get(SettingGroup.PAYMENTS)
    if (payments_group) {
      return payments_group.first().value
    }
    return new PaymentsRecord()
  }

  get_settings_signup() {
    const signup_group = this.settings.groupBy(s => s.group).get(SettingGroup.SIGNUP)
    if (signup_group) {
      return signup_group.first().value
    }
  }

  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.USER_STATUS: {
        if (action.logged_in) {
          const user = User.fromJS(action.user)
          analytics.identify(user.key,
            {
              name: user.info.name,
              email: user.email
            });
          return state.merge({
            user: user,
            logged_in: action.logged_in,
            auth_checked: true
          });
        }

        return this.getInitialState().merge({
          auth_checked: true
        });
      }
      case ActionTypes.USER_SETTINGS: {
        return state.merge({
          settings: SettingsFromJS(action.settings)
        });
      }
      case ProjectActionTypes.CREATE_PROJECT: {
        if (state.user.user_type === UserType.UNKNOWN) {
          // We do this because for UNKNOWN users we rely on this updating
          // with an eventually consistent query for projects.
          // So doing an actions.user_status() might be wrong at this point
          return state.set('user', state.user.set('user_type', UserType.CLIENT))
        }
        return state;
      }
      default:
        return state;
    }
  }
}

export const UserContext = React.createContext()

const userStore = new UserStore();
window.UserStore = userStore;
export default userStore;
