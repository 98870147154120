import React from 'react';

import {
  LinkContainer
} from 'react-router-bootstrap';

import {
  Jumbotron,
  Button
} from 'react-bootstrap';

import { analytics } from 'segment'

const Limbo = () => <Jumbotron className="text-center">
  <h1>Get Started</h1>

  <p className="text-primary">
    <LinkContainer
      to="/project/new#intro"
      onClick={() => analytics.track('Limbo - Select Create Project', { category: 'limbo' })}
    >
      <Button bsStyle="primary">Create a Project</Button>
    </LinkContainer><br />
    Looking for Translators or Transcribers? Click "Create a Project"
  </p>
  <p>
    <LinkContainer
      to="/provider/profile"
      onClick={() => analytics.track('Limbo - Select Create Profile', { category: 'limbo' })}
    >
      <Button>Create a Provider Profile</Button>
    </LinkContainer><br />
    If you are a service provider, click on "Create a Provider Profile"
  </p >

</Jumbotron >

export default Limbo;