import React, { Fragment } from 'react';

const LangRateSpan = ({
  lang_rate,
  muted,
  hide_rate,
  usual_lang_rate,
  rate_unit,
  children }) => {

  function AdditionalText() {
    function RateFragment() {
      if (lang_rate.has_rate) return <Fragment>at ₹<strong>{lang_rate.rate_display}</strong>/{rate_unit}</Fragment>;
      return '';
    }

    if (usual_lang_rate && usual_lang_rate.rate) {
      return (
        <span> <RateFragment /> (Usually: ₹{usual_lang_rate.rate_display}/{rate_unit})</span>
      );
    }

    return <span> <RateFragment /></span>

  }

  return <span className={muted ? 'text-muted' : ''}>
    {lang_rate.lang.display}
    {!hide_rate &&
      <AdditionalText />
    }
    {children}
  </span>
}

export default LangRateSpan