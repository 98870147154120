import { Record } from "immutable";

export class Review extends Record({
  work_again: undefined,
  quality: undefined,
  comments: '',
  comments_fs: ''
}) {
  static fromJS(jsObj) {
    return new Review(jsObj)
  }

  get valid() {
    const ret = {
      work_again: typeof (this.work_again) === 'boolean',
      quality: Number.isInteger(this.quality),
    }
    return { ...ret, all: Object.values(ret).every(v => v) }
  }
}
