import { ReduceStore } from 'flux/utils';

import dispatcher from 'dispatcher';

export default class ImmutableReduceStore extends ReduceStore {
  constructor() {
    super(dispatcher);
  }

  areEqual(one, two) {
    if (typeof (one) === "object" && typeof (one.equals) === "function") {
      return one.equals(two);
    }
    return one === two;
  }

}