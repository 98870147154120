import React, { useRef, useState } from 'react';

import useFluxStore from 'flux-hooks';

import {
  Breadcrumb,
  Button,
  Form, FormControl,
  Jumbotron
} from 'react-bootstrap';

import {
  LinkContainer
} from 'react-router-bootstrap';

import { useHistory } from 'react-router-dom';

import AlertDismissable from 'components/alert_dismissable';
import FormGroupCols from 'components/form_group_cols';

import UserStore from 'stores/userStore';

import { actions } from 'actions/user';

import { analytics } from 'segment'

export default function SetPassword({ from_settings }) {

  const email = useFluxStore(UserStore, (p, store) => store.user.email)
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirmPassword] = useState('')

  const alert = useRef()
  const history = useHistory()

  function updatePassword(e) {
    e.preventDefault();

    if (password.trim() !== confirm_password.trim()) {
      alert.current.showAlert('Passwords must match');
      analytics.track('Password Mismatch', { category: 'auth' })
      return;
    }
    alert.current.hideAlert();


    actions.set_password(password.trim(), confirm_password.trim())
      .then(() => {
        if (!from_settings) {
          history.push('/');
        } else {
          alert.current.showAlert("Changed Successfully", "Password", "success")
        }
        analytics.track('Password Set Success', { category: 'auth' })
      })
      .catch(err => alert.current.showAlert(err.reason));
  }



  return <React.Fragment>
    {from_settings && <React.Fragment>
      <Breadcrumb className="ellipsis">
        <LinkContainer to="/">
          <Breadcrumb.Item>
            Home
          </Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to='/settings'>
          <Breadcrumb.Item>
            Settings
          </Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          Password
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1 className='text-center'>Password</h1>
    </React.Fragment>}

    <Jumbotron className='text-center'>
      {!from_settings &&
        <>
          <h1>Set Password</h1>
          <p className="text-info">{email}</p>
        </>
      }
      <Form onSubmit={updatePassword} horizontal>
        <FormControl
          style={{ display: 'none' }}
          type="email"
          autoComplete="username"
          defaultValue={email} />

        <FormGroupCols label="Set Password">
          <FormControl
            type="password"
            autoComplete="new-password"
            placeholder="Set Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </FormGroupCols>

        <FormGroupCols label="Confirm Password">
          <FormControl
            type="password"
            autoComplete="new-password"
            placeholder="Confirm Password"
            value={confirm_password}
            onChange={e => setConfirmPassword(e.target.value)}
          />
        </FormGroupCols>

        <FormGroupCols>
          <Button bsStyle="success" type="submit">Set Password</Button>
        </FormGroupCols>
        <AlertDismissable errorTitle="Error" ref={alert} />
      </Form>

    </Jumbotron>
  </React.Fragment>
}
