/* eslint-disable */

export default {
    profile: {
        NAME: `This is your account's full name that will be shown to potential clients when viewing your profile.
            You can modify this by going to "Display Name" under Settings.`,
        DESCRIPTION: `Use the Description box to briefly share a little more about your work. Here is a sample: 
            "With experience for (# of years), I have worked on projects such as (names of social sector organizations or brief project details).
            I’m passionate about (any social causes you’re interested in)."`,
        AVAILABLE: `Set your availability status.
            Keep it on “Available” when you are actively seeking work and “Unavailable” when you don’t want to be matched for a project.
            Note that when you are “Unavailable” you won’t show up in any client search results, so use it wisely!`,
        NEGOTIABLE: `Check if you would like clients to know that your rates are negotiable`,
        CONTACT: `Click on “Add Contact” to update your contact information.
            This is how your work-team will get in touch with you.
            You can choose to add your phone number, email or both, but we require at least one mode of contact`,
        LANGUAGES: `Select your languages one-by-one.
            Use the rate fields to specify what you would like to charge.
            You can leave it blank if you wish but clients may prefer to connect with providers who have a listed rate.`,
        WRITER_CONTENT_TYPE: `Select the types of content you can work on`,
        PORTFOLIO: `Showcase samples of your work, websites and/or blogs`
    }
}