import dispatcher from 'dispatcher';
import Enum from 'enum';
import fetchPlus from 'fetchPlus';

import * as contract from 'actions/contract';
import * as assignment from 'actions/assignment';
import * as billing from 'actions/billing';

export const ActionTypes = new Enum([
  'FETCHED',
]);

const dispatchNotificationsFetched = ({ notifications, cursor, last_read, more }) => {
  dispatcher.dispatch({
    type: ActionTypes.FETCHED,
    notifications, cursor, last_read, more
  });
}

export const actions = {
  fetch(cursor = "") {
    return fetchPlus({
      url: `/api/notification/${cursor}`,
      success: json => {
        dispatcher.dispatch({
          type: contract.ActionTypes.UPDATE_CONTRACTS,
          contracts: json.contracts
        });

        dispatcher.dispatch({
          type: assignment.ActionTypes.UPDATE_ASSIGNMENTS,
          assignments: json.assignments
        });

        dispatcher.dispatch({
          type: billing.ActionTypes.UPDATE_INVOICES,
          invoices: json.invoices
        })

        dispatchNotificationsFetched(json);
      }
    });
  },

  mark_as_read(notif_key) {
    return fetchPlus({
      url: `/api/notification/mark_as_read/${notif_key}`,
      success: json => {
        dispatchNotificationsFetched(json);
      }
    });
  }
};
