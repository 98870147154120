import React, { useEffect } from 'react';

import ReactDOM from 'react-dom';

import 'index.css';

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';

import UserStore from 'stores/userStore';
import SyncStore from 'stores/syncStore';
import LangStore from 'stores/langStore';

import 'react-dates/initialize'; // required by https://github.com/airbnb/react-dates

import useFluxStore from 'flux-hooks';

import StackdriverErrorReporter from 'stackdriver-errors-js';

import { unregister } from 'registerServiceWorker';

import Topbar from 'topbar/topbar'
import Main from 'main'
import { analytics } from 'segment';
import Auth from 'auth';

const Index = () => {
  const { ready, logged_in, auth_checked } = useFluxStore(UserStore,
    (p, store) => ({
      ready: store.user.ready,
      logged_in: store.logged_in,
      ga_user_id: store.logged_in ? store.user.info.user_key : null,
      auth_checked: store.auth_checked
    }))

  const server_changed = useFluxStore(SyncStore,
    (p, store) => store.getState().server_changed)

  useFluxStore(LangStore, () => { })

  const history = useHistory();
  useEffect(() => {
    function onHistoryChange(location, action) {
      if (server_changed) {
        window.location.reload(true);
      }

      analytics.page(location.pathname + location.hash);
    }

    analytics.page(window.location.pathname + window.location.hash);

    // history.listen returns an unlisten function
    // https://github.com/ReactTraining/history
    return history.listen(onHistoryChange)
  }, [server_changed])// eslint-disable-line react-hooks/exhaustive-deps

  // We keep this for verify & password set
  const MainOrRedirectToAuth = ready ? Main : () => <Redirect to="/auth/" />

  return <>
    {auth_checked ?
      <>
        <Switch>
          <Route path="/faq/" component={RedirectToFaq} />
          {logged_in ?
            <>
              <Topbar />
              <Switch>
                <Route path="/auth/" component={Auth} />
                <Route path="/" component={MainOrRedirectToAuth} />
              </Switch>
            </>
            :
            <FwdToLogin />
          }
        </Switch>
      </> :
      <span>Loading...</span>
    }
  </>

}

function RedirectToFaq() {
  const { hash } = window.location
  window.location = `${getOrigin()}/faq/${hash}`
  return <span>Redirecting to FAQ: {`${getOrigin()}/faq/${hash}`}</span>
}

export function getOrigin() {
  const { origin, host } = window.location
  if (process.env.NODE_ENV === "development") {
    return `${origin.replace(host, host.replace('3000', '3001'))}`
  } else {
    return origin
  }
}

function FwdToLogin(props) {
  const { href } = window.location
  window.location = `${getOrigin()}/login/?next=${encodeURIComponent(href)}`
  return <span>Please login first, redirecting you to login...</span>
}


window.addEventListener('DOMContentLoaded', function () {
  var errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    targetUrl: '/api/err/client_error',
  });
})


ReactDOM.render(
  <BrowserRouter basename="/app">
    <Index />
  </BrowserRouter>,
  document.getElementById('root')
);

unregister();