import ContractType from './contract_type'
import TranslatorContract from './translator'
import TranscriberContract from './transcriber'
import WriterContract from './writer';
import { ContractState } from './contract'
import { ProfileType } from 'models/profile';

export { ContractState, ContractType, TranslatorContract, TranscriberContract }

export function ContractClassFromType(type) {
  switch (type) {
    case ContractType.TRANSLATOR:
      return TranslatorContract
    case ContractType.TRANSCRIBER:
      return TranscriberContract
    case ContractType.WRITER:
      return WriterContract
    default:
      throw Error("Unhandled contract type", type)
  }
}


export function ContractClassForProfileType(type) {
  switch (type) {
    case ProfileType.TRANSLATOR:
      return ContractClassFromType(ContractType.TRANSLATOR)
    case ProfileType.TRANSCRIBER:
      return ContractClassFromType(ContractType.TRANSCRIBER)
    case ProfileType.WRITER:
      return ContractClassFromType(ContractType.WRITER)
    default:
      throw Error("Unhandled profile type", type)
  }
}

export function fromJS(d) {
  const type = ContractType.get(d.contract_type)
  return ContractClassFromType(type).fromJS(d)
}