import React, { Suspense, lazy, useRef, useState } from 'react';

import { List, Map } from 'immutable';
import Enum from 'enum';
import {
  Glyphicon,
  Button, ButtonGroup,
  Radio,
  Jumbotron,
  Label,
  Tabs, Tab,
} from 'react-bootstrap';

import { actions } from 'actions/profile';
import { ProfileType, ProfileClassFromType } from 'models/profile';

import PersonalInfo from 'settings/personal_info';
import PaymentSettings from 'settings/payments';

import ProfileStore from 'stores/profileStore';
import UserStore from 'stores/userStore';

import HistoryPanel from 'components/history_panel';
import ProfileDisplay from 'components/profile/display';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

import FormGroupCols from 'components/form_group_cols';

import Future from './future'

import ProfileMode from './mode';

import useFluxStore from 'flux-hooks';
import { analytics } from 'segment';

export const SpecialTabs = new Enum(['CREATE', 'FUTURE', 'PERSONAL', 'PAYMENTS']);

const ProfileEditors = {}
ProfileType.enums.forEach(enumItem => {
  if (enumItem !== ProfileType.BASE) {
    ProfileEditors[enumItem] = lazy(() => import(`./${enumItem.key.toLowerCase()}`))
  }
});

export function ProviderProfileManager() {

  const tabs = useRef()

  const profiles = useFluxStore(ProfileStore, (p, store) => store.my())

  const info = useFluxStore(UserStore, (p, store) => store.user.info)

  const last_profiles = useRef(profiles)
  const last_info = useRef(info)

  const [saving, setSaving] = useState(Map())

  const new_profile_types_available = List(ProfileType.enums)
    .filterNot(t => t === ProfileType.BASE || profiles.has(t))


  const payment_settings = UserStore.get_settings_payments()

  function blank_new_profiles() {
    return new_profile_types_available
      .reduce((r, t) => {
        return r.set(t, new (ProfileClassFromType(t))({ info }))
      }, Map())
  }

  const [edit_profiles, setEditProfiles] = useState(blank_new_profiles());

  const [create_profile_type, setCreateProfileType] = useState(new_profile_types_available.first())

  if (!profiles.equals(last_profiles.current) || !info.equals(last_info.current)) {
    last_profiles.current = profiles;
    last_info.current = info
    setCreateProfileType(new_profile_types_available.first())
    setEditProfiles(blank_new_profiles())
  }

  function clickEditProfile(profile) {
    setEditProfiles(edit_profiles.set(profile.profile_type, profile))
  }

  function clickCancelChanges(profile_type) {
    setEditProfiles(edit_profiles.remove(profile_type))
  }

  const onUploadProgress = profile_type => progressEvent => {
    setSaving(m => m.set(profile_type, Math.round((progressEvent.loaded / progressEvent.total) * 99)))
  }

  function clickSaveChanges(profile) {
    setSaving(m => m.set(profile.profile_type, 0))
    analytics.track('Profile Edit Attempt', { category: 'profile', profile_type: profile.profile_type.key })
    actions.update_profile(profile, onUploadProgress(profile.profile_type)).then(() => {
      setEditProfiles(edit_profiles.remove(profile.profile_type))
      setSaving(m => m.remove(profile.profile_type))
      analytics.track('Profile Edit Success', { category: 'profile', profile_type: profile.profile_type.key })
    });
  }

  function switchToTab(tabKey) {
    tabs.current.props.onSelect(tabKey)
  }

  function onCreateProfile(profile) {
    setSaving(m => m.set(profile.profile_type, 0))
    analytics.track('Profile Create Attempt', { category: 'profile', profile_type: profile.profile_type.key })
    actions.create_profile(profile, onUploadProgress(profile.profile_type)).then(() => {
      switchToTab(SpecialTabs.PAYMENTS)
      setSaving(m => m.remove(profile.profile_type))
      analytics.track('Profile Create Success', { category: 'profile', profile_type: profile.profile_type.key })
    });
  }

  function onProfileChange(profile) {
    setEditProfiles(edit_profiles.set(profile.profile_type, profile))
  }


  function onCreateTypeChange(e) {
    setCreateProfileType(ProfileType.get(e.target.value))
  }

  function CreateButton({ profile }) {
    const prog = saving.get(profile.profile_type)

    return <Button
      bsStyle="success"
      disabled={!profile.valid_all || !!prog}
      onClick={() => onCreateProfile(profile)}
    >
      {prog > 0 ? `Creating Profile: ${prog}%` : 'Create Profile'}
    </Button>
  }

  function ActionButtons({ profile, mode }) {
    const prog = saving.get(profile.profile_type)

    switch (mode) {
      case ProfileMode.EDIT:
        return <ButtonGroup>
          <Button
            bsStyle="success"
            disabled={!profile.valid_all || !!prog}
            onClick={() => clickSaveChanges(profile)}
          >
            {prog > 0 ? `Saving ${prog}%` : 'Save Changes'}
          </Button>
          <Button onClick={() => clickCancelChanges(profile.profile_type)}>Cancel</Button>
        </ButtonGroup>
      case ProfileMode.LOADING:
        return <ButtonGroup>
          <Button
            disabled={true}
          >
            Loading...
          </Button>
        </ButtonGroup>
      case ProfileMode.DISPLAY:
        return <ButtonGroup>
          <Button bsStyle="warning" onClick={() => clickEditProfile(profile)}>
            Edit
          </Button>
        </ButtonGroup>
      default:
        return <div />
    }
  }



  const NewProfileEditor = create_profile_type ? ProfileEditors[create_profile_type] : undefined

  return <React.Fragment>
    {!info.valid.contacts &&
      <Jumbotron className="text-center">
        <h1 className="text-center">Start with the basics!</h1>
        <p>
          To create a profile, please <strong>add contact information
          </strong> so clients can reach out to you<br />
        </p>
      </Jumbotron>
    }


    <Tabs
      ref={tabs}
      id="profile-tabs"
      animation={false}
    >
      {/* PROFILE DISPLAY / EDIT TABS */}
      {profiles.valueSeq().map(p => {
        const ProfileEditor = ProfileEditors[p.profile_type]
        const edit_profile = edit_profiles.get(p.profile_type)
        const mode = edit_profile ? ProfileMode.EDIT : ProfileMode.DISPLAY
        return <Tab key={p.profile_type} eventKey={p.profile_type} title={<Label>{p.profile_type.key}</Label>}>
          <div style={{ float: 'right' }}>
            <ActionButtons mode={mode} profile={edit_profile || p} />
          </div>
          <Jumbotron>
            {(mode === ProfileMode.EDIT) ?
              <Suspense fallback={<h1 className="text-center">Loading...</h1>}>
                <ProfileEditor onChange={onProfileChange} profile={edit_profile} />
              </Suspense>
              :
              <ProfileDisplay my_profile profile={p} />
            }
          </Jumbotron>
          <HistoryPanel type="profile" activity_key={profiles.get(p.profile_type).key} />

        </Tab>
      })}

      {info.valid.contacts && !new_profile_types_available.isEmpty() &&
        <Tab eventKey={SpecialTabs.CREATE} tabClassName="special_create_tab" title="+ Add Profile">
          <Jumbotron>
            <div style={{ float: 'right' }}>
              <ActionButtons mode={ProfileMode.NEW} profile={edit_profiles.get(create_profile_type)} />
            </div>
            <FormGroupCols id="profile-type-selector" label="Profile Type" rightColClassName="text-center">
              {new_profile_types_available.map(profile_type =>
                <Radio className="space-right"
                  inline name='profile_type_group'
                  key={profile_type.key} value={profile_type.key}
                  onChange={onCreateTypeChange}
                  checked={profile_type === create_profile_type}>
                  {profile_type.key}
                </Radio>
              )}
              {/* new_profile_types_available.forEach(profile_type => {
                if (profile_type !== ProfileType.BASE) {
                  profile_type_options.push(
                    <Radio inline name='profile_type_group' onChange={onCreateTypeChange}
                      key={profile_type.key} value={profile_type.key} checked={profile_type.key ===}>
                      {profile_type.key}
                    </Radio>)
                }
              }) */}
            </FormGroupCols>
            <Suspense fallback={<h1 className="text-center">Loading...</h1>}>
              <NewProfileEditor
                onChange={onProfileChange}
                profile={edit_profiles.get(create_profile_type)} />
            </Suspense>

            <div className="text-center" style={{ marginTop: '1em' }}>
              <FormGroupCols>
                <CreateButton profile={edit_profiles.get(create_profile_type)} />
              </FormGroupCols>
            </div>
          </Jumbotron>
        </Tab>
      }
      {/* PERSONAL INFO TAB */}
      <Tab eventKey={SpecialTabs.PERSONAL} tabClassName="special_personal_tab" title="Personal">
        <PersonalInfo inProfileEdit={true} />
      </Tab>

      {!profiles.isEmpty() &&
        < Tab eventKey={SpecialTabs.PAYMENTS} tabClassName="special_payments_tab" title={<span>
          Payments {!payment_settings.payments_agreement &&
            <Glyphicon glyph="exclamation-sign text-warning" />
          }</span>}>
          {/* PAYMENTS TAB */}
          <PaymentSettings inProfileEdit={true} />
        </Tab>
      }
      {!profiles.isEmpty() &&
        < Tab eventKey={SpecialTabs.FUTURE} tabClassName="special_future_tab" title="Future">
          {/* FUTURE TAB */}
          <Future />
        </Tab>
      }
    </Tabs>
  </React.Fragment >

}
