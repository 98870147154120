import Enum from 'enum';

import dispatcher from 'dispatcher';

import fetchPlus from 'fetchPlus';
import axios from 'axios';

export const ActionTypes = new Enum([
  'PROJECT_DOCUMENTS',
  'UPDATE_DOCUMENTS',
  'UPDATE_ASSIGNMENTS'
]);

export const actions = {
  get_for_project(project_key) {
    return fetchPlus({
      url: `/api/doc/get_for_project/${project_key}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.PROJECT_DOCUMENTS,
          project_key,
          documents: json.documents
        });
      }
    });
  },

  get_documents(doc_keys) {
    return fetchPlus({
      url: '/api/doc/get_documents',
      body_obj: {
        doc_keys
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_DOCUMENTS,
          documents: json.documents
        });
      }
    });
  },

  get_document(document_key) {
    return fetchPlus({
      url: `/api/doc/get_document.json?document_key=${document_key}`,
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_DOCUMENTS,
          documents: [json.document]
        });
      }
    });
  },

  archive(doc_keys) {
    return fetchPlus({
      url: '/api/doc/archive',
      body_obj: {
        doc_keys
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_DOCUMENTS,
          documents: json.documents
        });
      }
    })
  },

  archive_restore(doc_keys) {
    return fetchPlus({
      url: '/api/doc/archive_restore',
      body_obj: {
        doc_keys
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_DOCUMENTS,
          documents: json.documents
        });
      }
    })
  },

  delete_permenantly(doc_keys) {
    return fetchPlus({
      url: '/api/doc/delete_permenantly',
      body_obj: {
        doc_keys
      },
      method: 'POST',
      success: json => {
        dispatcher.dispatch({
          type: ActionTypes.UPDATE_DOCUMENTS,
          documents: json.documents
        });
      }
    })
  },

  update_document(doc, onUploadProgress) {
    const data = new FormData();
    data.append('doc', JSON.stringify(doc))

    return new Promise(resolve => {
      if (doc.resources.some(att => att.has_upload)) {
        doc.resources.map((att, k) => att.has_upload && data.append(`att_${k}`, att.upload.file))
        fetchPlus({
          url: `/api/doc/update_document/${doc.key}`
        }).then(resp => resolve(resp['upload_url']))
      } else {
        resolve('/api/doc/update_document')
      }
    }).then(url => axios.request({
      url,
      method: 'POST',
      data,
      onUploadProgress
    })).then(({ data }) => {
      dispatcher.dispatch({
        type: ActionTypes.UPDATE_DOCUMENTS,
        documents: [data.document]
      });
    })
  }
};
