import {
  Record
} from 'immutable'

import AssignmentStore from 'stores/assignmentStore'
import ContractStore from 'stores/contractStore'
import InvoiceStore from 'stores/invoiceStore'

export const NotificationBase = defaultValues => class extends Record({
  key: undefined,
  created: undefined,
  creator: undefined,
  msg: undefined,
  about: undefined,
  about_type: undefined,
  action_type: undefined,
  ...defaultValues
}) {
  static supJS(jsObj) {
    jsObj.created = Date.parse(jsObj.created);
    return jsObj
  }

  get url() {
    switch (this.about_type) {
      case 'Assignment':
        return AssignmentStore.get_by_key(this.about).url;
      case 'Contract':
        if (this.action_type === 'Propose') {
          return ContractStore.get_by_key(this.about).project.url;
        }
        return ContractStore.get_by_key(this.about).url;
      case 'Invoice':
        return InvoiceStore.get_by_key(this.about).url;
      default:
        throw Error("Unknown type of notification", this);
    }
  }
}

export class Notification extends NotificationBase({}) {
  static fromJS(jsObj) {
    return new Notification(super.supJS(jsObj));
  }
}

export class Callback extends NotificationBase({
  proj: undefined,
  work: undefined,
}) {
  static fromJS(jsObj) {
    const s = super.supJS(jsObj)
    s.proj = jsObj.proj
    s.work = jsObj.work

    return new Callback(s)
  }
}

export function fromJS(jsObj) {
  switch (jsObj.action_type) {
    case "CallbackNotif":
      return Callback.fromJS(jsObj)
    default:
      return Notification.fromJS(jsObj)
  }
}