import React from 'react'

import InfoPopover from 'components/info_popover';

export const StateMatchInfo = ({ record }) => {
  if (!record.valid.has_gst || !record.valid.gst.number || record.valid.gst.state_match) return <React.Fragment />

  return <React.Fragment>
    <span className="text-warning"><InfoPopover controlId=''>
      State mentioned is <strong>{record.state_display}</strong>, however
                your GST registration is for <strong>{record.gst_state_display}</strong>.<br />
      Please make sure your address and GST Registrations match.
    </InfoPopover> GST Registration not for <strong>{record.state_display}</strong>
    </span>

  </React.Fragment>
}
