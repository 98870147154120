import React from 'react';

import Contact, { ContactType, ContactTypeName } from 'models/contact';
import ContactGlyph from 'components/contact/glyph';

import {
  InputGroup,
  DropdownButton, MenuItem,
  Button,
  FormGroup,
  FormControl,
  Glyphicon,
  ListGroup, ListGroupItem,
} from 'react-bootstrap';


const ContactListEdit = ({ contacts, onContactsUpdate }) => {
  const onDelete = idx => onContactsUpdate(contacts.remove(idx));

  const onAdd = () => onContactsUpdate(contacts.push(new Contact()));

  const onChangeValue = (idx, value) =>
    onContactsUpdate(contacts.update(idx, contact => contact.set('value', value)));

  const onChangeType = (idx, type_str) =>
    onContactsUpdate(contacts.update(idx, c => c.set(
      'contact_type', ContactType.get(parseInt(type_str, 10)))));

  return <div className="text-center">
    <ListGroup>
      {contacts.toArray().map((c, idx) => {
        return (
          <FormGroup
            style={{ marginBottom: 0 }}
            key={c.get('contact_type').key + idx}
            controlId={'formContact' + c.get('contact_type').key + idx}
            className="text-center"
            validationState={!c.valid ? 'warning' : 'success'}
          >
            <ListGroupItem>
              <InputGroup>
                <InputGroup.Addon>
                  <DropdownButton
                    componentClass={InputGroup.Button}
                    id="input-dropdown-addon"
                    onSelect={e => onChangeType(idx, e)}
                    key={c.contact_type.value}
                    title={<ContactGlyph contact_type={c.contact_type} />}
                  >
                    {ContactType.enums.map(item => (
                      <MenuItem
                        key={item.value}
                        eventKey={item.value}
                        style={{ textAlign: 'center' }}
                      >
                        {<ContactGlyph contact_type={item} />}
                      </MenuItem>
                    ))}
                  </DropdownButton>
                </InputGroup.Addon>
                <FormControl
                  type="text"
                  style={{
                    textAlign: 'center',
                  }}
                  placeholder={ContactTypeName(c.contact_type)}
                  onChange={e => onChangeValue(idx, e.target.value)}
                  value={c.value} />
                <InputGroup.Addon>
                  <Button onClick={() => onDelete(idx)} bsSize="small">
                    <Glyphicon glyph="trash" />
                  </Button>
                </InputGroup.Addon>
              </InputGroup>
            </ListGroupItem>
          </FormGroup>
        );
      })}
    </ListGroup>
    <Button onClick={onAdd}>Add Contact</Button>
  </div>
}

export default ContactListEdit;