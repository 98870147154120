import fetchPlus from 'fetchPlus';

export const actions = {
  msg(email, msg) {
    return fetchPlus({
      url: '/api/support/msg',
      method: 'POST',
      body_obj: {
        email, msg
      },
    });
  },
}