import ImmutableReduceStore from 'stores/immutabe-reduce-store';
import { Record } from 'immutable';
import { FETCH_EVENT } from 'fetchPlus';
import { actions } from 'actions/user';

var hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

const REFRESH_TIMEOUT_MS = 1000 * 60 * 5; // 1000ms * 60s * 5 minutes

class SyncStore extends ImmutableReduceStore {
  _timeout = null
  _hide_start = 0

  constructor() {
    super()
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    this.handleVisibilityChange()
  }

  checkServerVersion = () => {
    // If we know the server has changed, no more timeouts required
    if (this._state.server_changed) return

    const checked_since = this._state ? Date.now() - this._state.updated : Date.now()

    if (checked_since >= REFRESH_TIMEOUT_MS) {
      actions.user_status().catch(e => console.log("DOWN?", e))
      this._timeout = setTimeout(this.checkServerVersion, REFRESH_TIMEOUT_MS)
    } else {
      this._timeout = setTimeout(this.checkServerVersion, REFRESH_TIMEOUT_MS - checked_since)
    }

  }

  handleVisibilityChange = () => {
    if (document[hidden]) {
      clearTimeout(this._timeout)
      this._hide_start = Date.now()
    } else {
      this.checkServerVersion()
    }
  }


  getInitialState() {
    return new Record({
      version: null,
      server_changed: false,
      updated: Date.now()
    })();
  }

  get server_changed() {
    return this._state.server_changed
  }

  dub(v) {
    return 2 * v
  }

  reduce(state, action) {
    switch (action.type) {
      case FETCH_EVENT.SERVER_CHANGE:
        return state.withMutations(s => {
          const { version } = action
          // We check if version has ever been set
          // If set before, compare 
          // If not set version and continue onwards
          s.set('server_changed', s.version ? (s.server_changed || s.version !== version) : false)
          s.set('version', version)
          s.set('updated', Date.now())
        })
      default:
        return state
    }
  }
}

const syncStore = new SyncStore();
window.SyncStore = syncStore;
export default syncStore;
