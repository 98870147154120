import ImmutableReduceStore from 'stores/immutabe-reduce-store';

import { OrderedMap, Map, Record, List } from 'immutable';

import { actions, ActionTypes } from 'actions/profile';
import * as user from 'actions/user';
import { ActionTypes as ProjectActionTypes } from 'actions/project';
import { fromJS } from 'models/profile';

function state_with_my_profiles(state, jsProfiles) {
  return state.withMutations(s => {
    const profileMap = {}
    const my_profile_keys = []
    jsProfiles.forEach(jsP => {
      const p = fromJS(jsP);
      profileMap[p.profile_type] = p
      my_profile_keys.push(p.key);
      s.setIn(['profiles', p.key], p);
    })
    s.set('my', List(my_profile_keys));
  });
}

class ProfileStore extends ImmutableReduceStore {
  getInitialState() {
    return new Record({
      my: List(),
      candidate_profiles: Map(),
      profiles: Map(),
      profiles_a: Map()
    })();
  }

  my() {
    return this._state.my.reduce((r, key) => {
      const p = this._state.profiles.get(key)
      return r.set(p.profile_type, p)
    }, OrderedMap());
  }

  candidates_for_project(project_key) {
    if (!this._state.candidate_profiles.has(project_key)) {
      actions.project_candidate_profiles(project_key);
      return;
    }

    return this._state.candidate_profiles.get(project_key)
  }

  get_profiles(profile_keys) {
    const pk_set = profile_keys.toSet()

    if (pk_set.isEmpty()) return Map()

    const remaining_profiles = pk_set.subtract(this._state.profiles.keys());

    if (remaining_profiles.size > 0) {
      actions.get_profiles(remaining_profiles);
    }

    return this._state.profiles.filter(p => pk_set.has(p.key));
  }

  get_profile(profile_key) {
    if (!profile_key) return undefined;

    const profile = this._state.profiles.get(profile_key);

    if (!profile) {
      actions.get_profiles([profile_key]);
    }

    return profile;
  }

  get_profiles_a(q) {
    if (!q) return List()
    const ret = this._state.profiles_a.get(q.hashCode())
    if (!ret) {
      actions.get_profiles_a(q)
      return Map()
    }

    return this.get_profiles(ret)
  }

  get_provider_user_info(user_key) {
    return this._state.profiles.find(profile => profile.user_key === user_key)
  }

  reduce(state, action) {
    switch (action.type) {
      case ActionTypes.MY_PROFILE: {
        return state_with_my_profiles(state, action.profiles);
      }

      case ActionTypes.UPDATE_PROFILE: {
        return state.withMutations(s => {
          const p = fromJS(action.profile);
          s.setIn(['profiles', p.key], p);
        });
      }

      case ActionTypes.GET_PROFILES: {
        return state.withMutations(mState => {
          action.profiles.forEach(profile_js => {
            const p = fromJS(profile_js);
            mState.setIn(['profiles', p.key], p);
          });
        });
      }


      case ActionTypes.GET_PROFILES_A: {
        return state.withMutations(mState => {
          action.profiles_a.forEach(profile_js => {
            const p = fromJS(profile_js);
            mState.setIn(['profiles', p.key], p);
          });
          mState.setIn(['profiles_a', action.q.hashCode()], List(action.profiles_a.map(p => p['key'])))
        });
      }

      case ActionTypes.PROJECT_CANDIDATE_PROFILES: {
        const profs = List(action.profiles.map(fromJS));
        return state.setIn(['candidate_profiles', action.project_key], profs)
      }

      case ProjectActionTypes.CREATE_PROJECT:
      case ProjectActionTypes.UPDATE_PROJECT: {
        return state.update('candidate_profiles', ct => ct.delete(action.project.key))
      }
      case user.ActionTypes.USER_STATUS: {
        if (action.logged_in) {
          if ('profiles' in action && action.profiles) {
            actions.my();

            return state_with_my_profiles(state, action.profiles);
          }
          return state;
        }
        return this.getInitialState();
      }

      default:
        return state;
    }
  }
}

window.profileStore = new ProfileStore();
export default window.profileStore;
