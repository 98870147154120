import {
  OrderedSet,
  Record,
  List,
} from 'immutable';

import Enum from 'enum';

import { PaymentsRecord } from 'models/settings';
import { Review } from 'models/review';


export const InvoiceState = new Enum({
  REQUEST: 1,
  PAID: 5,
  REJECTED: 8,
  CANCELLED: 9,
});

export class Invoice extends Record({
  key: undefined,
  uid: undefined,
  state: InvoiceState.REQUEST,
  provider_user_key: undefined,
  provider_payments: undefined,
  project_key: undefined,
  assignment_keys: OrderedSet(),
  adjustments: List(),
  total: undefined,
  work: undefined,
  reject_reason: undefined,
  razorpay_order: undefined,
  razorpay_payments: undefined,
  created: undefined,
  updated: undefined,
  review_provider: undefined,
  review_client: undefined

}) {
  static fromJS(jsObj) {
    const ret = Object.assign({}, jsObj); // We do this so we don't modify the original object
    // For shenanigans google analytics reasons, our refresh() is checked by the handler
    // that performs the refresh
    ret.state = InvoiceState.get(ret.state);
    ret.assignment_keys = OrderedSet(ret.assignments);

    ret.adjustments = List(jsObj.adjustments.map(adj => new InvoiceAdjustment(adj)))
    if (jsObj.provider_payments) {
      ret.provider_payments = PaymentsRecord.fromJS(ret.provider_payments)
    }
    ret.created = Date.parse(ret.created);
    ret.updated = Date.parse(ret.updated);

    if (jsObj.review_provider) {
      ret.review_provider = Review.fromJS(jsObj.review_provider)
    }
    if (jsObj.review_client) {
      ret.review_client = Review.fromJS(jsObj.review_client)
    }
    return new Invoice(ret)
  }

  get active() {
    return this.state <= InvoiceState.PAID
  }

  get url() {
    return `/invoice/${this.uid}`
  }

  get display() {
    return `Invoice #${this.uid}`
  }

  get pdf() {
    return `/api/billing/invoice_pdf/${this.uid}`;
  }

  get pdf_s() {
    return `${this.pdf}S`
  }

  get review() {
    if (this.review_provider && this.review_client) {
      throw Error("Both type of reviews?")
    }
    return this.review_provider || this.review_client
  }
}

export class InvoiceAdjustment extends Record({
  description: undefined,
  amount: undefined
}) { }