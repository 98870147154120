import React from 'react';

import { PINCODE } from './pincode'
import { STATES_AND_UT } from './STATES_AND_UT'

export { STATES_AND_UT }

export const DistrictDisplay = ({ addr }) => {
  if (!addr.state || !addr.dist) return <span className="text-danger">Please Update State &amp; District</span>
  return `${addr.dist}, ${STATES_AND_UT[addr.state]} ${addr.postal_code}`
}

export const default_choice = postal_code => {
  const pin_map = PINCODE[postal_code]
  if (pin_map) {
    const state_options = Object.keys(pin_map)
    if (state_options.length === 1) {
      const dist_options = pin_map[state_options[0]]
      if (dist_options.length === 1) {
        return [postal_code, dist_options[0], state_options[0]]
      }
    }
  }

  return [postal_code, '', '']

}

export const PostalCodeStateOptions = ({ addr }) => {
  const pin_map = PINCODE[addr.postal_code]
  if (!pin_map) return <option value="">Enter valid Postal Code...</option>
  return <React.Fragment>
    {!addr.dist &&
      <option value="">Please Select...</option>
    }
    {
      Object.entries(pin_map).map(([state_code, dists]) => <React.Fragment key={state_code} >
        {dists.map(dist => <option key={dist} value={`${dist},${state_code}`}>{dist}, {STATES_AND_UT[state_code]}
        </option>
        )}
      </React.Fragment>)
    }
  </React.Fragment>
}