import {
  Record
} from 'immutable';

import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

import Enum from 'enum';

import { List } from 'immutable';

export const ContactType = new Enum({
  EMAIL: 0,
  MOBILE: 1
});

export const ContactTypeName = contact_type => {
  switch (contact_type) {
    case ContactType.EMAIL:
      return "Email Address"
    case ContactType.MOBILE:
      return "Phone Number"
    default:
      throw Error('Unknown Contact Type')
  }
}

export const ContactsFromUserDetails = (contacts, email, signup_settings) => {
  if (!contacts.isEmpty()) {
    return contacts
  }

  // If no contacts in personal info
  const l = []
  // Use user's email address
  l.push(new Contact({ contact_type: ContactType.EMAIL, value: email }))

  // pull phone number from signup data

  const phone = signup_settings && signup_settings.getIn(['SignupData', 'value', 'phone'])
  if (phone) {
    l.push(new Contact({
      contact_type: ContactType.MOBILE,
      value: phone
    }))
  }

  return List(l)
}

export default class Contact extends Record({
  contact_type: ContactType.EMAIL,
  value: '',
}) {
  get complete() {
    return this.get('value').trim() !== '';
  }

  get key() {
    return this.get('contact_type').value + this.get('value');
  }

  get valid() {
    if (this._valid) return this._valid;
    switch (this.contact_type) {
      case ContactType.EMAIL:
        this._valid = isEmail(this.value); break;
      case ContactType.MOBILE:
        this._valid = isMobilePhone(this.value, 'any') && !(this.value.length < 6); break;
      default:
        throw Error("Unknown ContactType", this.contact_type);
    }

    return this._valid;
  }

  static fromJS(jsObj) {
    return new Contact({
      contact_type: ContactType.get(jsObj.contact_type),
      value: jsObj.value
    });
  }
}