import { LangPairContract } from './contract'
import ContractType from './contract_type'
import { TranslatorAssignment } from 'models/assignment'
import { WorkUnit } from 'models/cost';

export default class TranslatorContract extends LangPairContract({
  contract_type: ContractType.TRANSLATOR,
  verb: 'Translate',
  work_unit: WorkUnit.word
}) {
  static fromJS(jsObj) {
    const sup = super.supJS(jsObj);
    return new TranslatorContract(sup);
  }

  get ASSIGNMENT_CLASS() {
    return TranslatorAssignment
  }
}