import { Record } from 'immutable';

import { List } from 'immutable';
import isLength from 'validator/lib/isLength'
import isNumeric from 'validator/lib/isNumeric'

import { isValidPIN, isValidIFSC, isValidGST, isValidPAN, STATE_FROM_GST } from 'models/settings/payments_helper'
import { STATES_AND_UT } from 'components/pincode/STATES_AND_UT'

export class PaymentsRecord extends Record({
  name: '',
  phone: '',
  address: '',
  postal_code: '',
  dist: '',
  state: '',
  has_gst: undefined,
  gst: '', // 27AAPFU0939F1ZV
  pan: '', // AAPFU0939F
  bank_ifsc: '',
  bank_account: '',
  bank_account_confirm: '',
  bank_account_type: '',
  bank_account_holder_name: '',
  payments_agreement: false,
}) {
  static fromJS(obj) {
    return new PaymentsRecord(obj)
  }

  get dist_state() {
    return `${this.dist},${this.state}`
  }

  get state_display() {
    return STATES_AND_UT[this.state]
  }

  get gst_state() {
    return this.has_gst && isValidGST(this.gst) ? STATE_FROM_GST(this.gst) : null
  }

  get gst_state_display() {
    return this.gst_state && STATES_AND_UT[this.gst_state]
  }


  get valid() {
    if (this._valid) return this._valid;

    const {
      name, phone, address,
      postal_code, dist, state,
      pan,
      has_gst, gst, gst_state,
      bank_ifsc,
      bank_account_holder_name, bank_account_type, bank_account, bank_account_confirm,
      payments_agreement
    } = this

    const valid = {
      name: isLength(name.trim(), { min: 3 }),
      phone: isLength(phone.trim(), { min: 6 }),
      address: isLength(address.trim(), { min: 10 }),
      postal_code: isValidPIN(postal_code),
      dist_state: !!dist && !!state,
      pan: isValidPAN(pan),
      has_gst: has_gst !== undefined,
      gst: has_gst ? {
        number: isValidGST(gst),
        state_match: !state || gst_state === state
        // The abomination above: 
        // Should be false only if state is defined and doesn't match
      } : {},
      bank_ifsc: isValidIFSC(bank_ifsc),
      bank_account: {
        name: isLength(bank_account_holder_name.trim(), { min: 3 }),
        type: bank_account_type === 'SAVINGS' || bank_account_type === 'CURRENT',
        number: isNumeric(bank_account) && isLength(bank_account.trim(), { min: 6 }),
      },
      payments_agreement: payments_agreement === true
    }

    valid['bank_account']['number_confirm'] = valid.bank_account.number && bank_account === bank_account_confirm

    let all = true
    for (const key in valid) {
      if (typeof (valid[key]) === "object") {
        const sub_all = List(Object.values(valid[key])).every(v => v)
        valid[key]['all'] = sub_all
        all = all && sub_all
      } else {
        all = all && valid[key]
      }
    }

    valid.all = all
    return valid
  }
}