import React from 'react';
import TranslatorProfileDisplay from './translator'
import TranscriberProfileDisplay from './transcriber'
import WriterProfileDisplay from './writer';

import { ProfileType } from 'models/profile';

export default function ProfileDisplay({ profile, ...props }) {
  if (!profile) return (<div />);
  switch (profile.profile_type) {
    case ProfileType.TRANSLATOR:
      return <TranslatorProfileDisplay profile={profile} {...props} />
    case ProfileType.TRANSCRIBER:
      return <TranscriberProfileDisplay profile={profile} {...props} />
    case ProfileType.WRITER:
      return <WriterProfileDisplay profile={profile} {...props} />
    default:
      throw Error("Unknown profile_type: " + profile.profile_type)
  }
}