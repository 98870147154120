import React, { useState, useRef } from 'react';

import {
  Button,
  ButtonGroup,
  Checkbox,
  Jumbotron,
  Form, FormGroup,
} from 'react-bootstrap';

import Textarea from "react-textarea-autosize";

import UserStore from 'stores/userStore';

import { actions } from 'actions/user';
import { SettingGroup, SpecificService, FutureType } from 'models/settings';

import FormGroupCols from 'components/form_group_cols';

import useFluxStore from 'flux-hooks'
import { analytics } from 'segment';

export default function Future() {
  const settings = useFluxStore(UserStore, (p, store) => store.settings
    .groupBy(s => s.group)
    .get(SettingGroup.FUTURE))


  const last_settings = useRef(settings)
  const [updated_settings, setUpdatedSettings] = useState(settings)

  if (settings && !settings.equals(last_settings.current)) {
    last_settings.current = settings
    setUpdatedSettings(settings)
  }

  const onChange = (future_type, value, specific_skill) => {
    switch (future_type) {
      case FutureType.FutureSpecificServices:
        return setUpdatedSettings(updated_settings
          .update(future_type, s => s
            .update(
              'value',
              set => value ? set.add(specific_skill) : set.delete(specific_skill))))
      case FutureType.FutureOtherServices:
        return setUpdatedSettings(updated_settings
          .update(future_type, s => s.set('value', value)))
      default:
        throw new Error("Unkonwn FutureType", future_type)
    }
  }


  const saveFuture = () => {
    actions.update_settings(
      updated_settings.map(s => [s.type, s.value]).valueSeq()
    ).then(() =>
      analytics.track('Profile Future Settings Updated', { category: 'profile' })
    )
  }


  if (!updated_settings) return <div />;

  const specific_skills = updated_settings.get(FutureType.FutureSpecificServices).value;
  const other_skills = updated_settings.get(FutureType.FutureOtherServices).value;

  function strAndDescriptionForService(svc) {
    switch (svc) {
      case SpecificService.TRANSCRIPTION: return "Transcription";
      case SpecificService.LIVE_TRANSLATION: return "Live translation or interpretation";
      case SpecificService.FDC_SURVEYOR: return "Field data collection - Surveyor";
      case SpecificService.FDC_QUANT: return "Field data collection - Qualitative facilitator/ moderator";
      case SpecificService.FDC_SUPER: return "Field data collection - Supervisor";
      case SpecificService.DATA_ENTRY: return "Data Entry";
      case SpecificService.REPORT_WRITING: return "Report Writing";
      case SpecificService.GRANT_WRITING: return "Grant Writing";
      case SpecificService.DATA_ANALYSIS: return "Data Analysis"
      default: throw Error("Unknown Specific Skill:" + svc);
    }
  }

  const checboxes = [];
  const IGNORE_SERVICE = [SpecificService.TRANSCRIPTION, SpecificService.REPORT_WRITING, SpecificService.GRANT_WRITING]

  SpecificService.enums.forEach(s => {
    if (IGNORE_SERVICE.includes(s)) return
    checboxes.push(
      <FormGroup key={s.key}>
        <Checkbox
          data-id={s.key}
          onChange={e => onChange(FutureType.FutureSpecificServices, e.target.checked, s)}
          checked={specific_skills.includes(s)}
        >
          {strAndDescriptionForService(s)}
        </Checkbox>
      </FormGroup>
    );
  });

  const changed = !settings.equals(updated_settings);

  return <React.Fragment>
    <div style={{ float: 'right' }}>
      <ButtonGroup>
        <Button
          disabled={!changed}
          bsStyle={changed ? "success" : null}
          onClick={saveFuture}
        >
          {changed ? "Save" : "No Changes"}
        </Button>
      </ButtonGroup>
    </div>
    <Jumbotron className="text-center">
      <h1>For the Future</h1>
      {/* <h4>Do you have other skills you want featured?</h4> */}
      <h4>We're building a community of top-notch providers for the social sector!</h4>
      <p>Please select services you have experience in:</p>
      <Form horizontal>
        <div className="multiline-left-center">
          {checboxes}
        </div>
        <br />
        <FormGroupCols label='Other Skills'>
          <Textarea
            data-id='other'
            className="form-control"
            style={{ width: '100%' }}
            placeholder="Any other skills that haven't been mentioned above"
            value={other_skills}
            onChange={e => onChange(FutureType.FutureOtherServices, e.target.value)}
          />
        </FormGroupCols>
      </Form>
      <p>We'll get in touch with you about these services when we're ready!</p>
    </Jumbotron>
  </React.Fragment>
}
