import Enum from 'enum';

import isURL from 'validator/lib/isURL'

import UploadedFile from 'models/uploaded_file'
import { Record } from 'immutable';

export const AttachmentItemType = new Enum({
  LINK: 0,
  UPLOAD: 1
})

export class AttachmentItem extends Record({
  item_id: undefined,
  item_type: undefined,
  upload: undefined,
  name: undefined,
  url: undefined,
  delete: false,
}) {
  static fromFile(file) {
    return new AttachmentItem({
      name: file.name,
      item_type: AttachmentItemType.UPLOAD,
      upload: UploadedFile.fromFile(file)
    })
  }

  static fromJS(jsObj, route, profile_key) {
    jsObj.item_type = AttachmentItemType.get(jsObj.item_type)
    if (jsObj.item_type === AttachmentItemType.UPLOAD) {
      jsObj.url = `/api/${route}/att_dl/${profile_key}/${jsObj.item_id}/${jsObj.name}`
    }

    return new AttachmentItem(jsObj)
  }

  get has_upload() {
    return this.item_type === AttachmentItemType.UPLOAD && this.upload?.file
  }

  get valid() {
    if (this._valid) return this._valid
    this._valid = {
      name: !!(this.name && this.name.trim().length > 2),
    }
    switch (this.item_type) {
      case AttachmentItemType.LINK:
        this._valid['url'] = !!(this.url && isURL(this.url, { require_protocol: true }));
        break;
      case AttachmentItemType.UPLOAD:
        this._valid['upload'] = !!this.upload;
        break;
      default:
        throw Error("Unhandled item_type")
    }

    this._valid['all'] = Object.values(this._valid).every(v => v);
    return this._valid;
  }
}