import React, {
  Component
} from 'react';

import {
  Alert
} from 'react-bootstrap';

import PropTypes from 'prop-types';

export default class AlertDismissable extends Component {
  constructor(props) {
    super();
    this.state = {
      errorTitle: props.errorTitle,
      bsStyle: 'danger',
      visible: false
    };
  }

  showAlert = (errorText, errorTitle, bsStyle = "danger") => {
    this.setState({
      visible: true,
      errorText,
      bsStyle,
      errorTitle
    });
  }

  hideAlert = () => {
    this.setState({
      visible: false,
      errorText: ''
    });
  }

  render() {
    const {
      visible,
      errorText,
      bsStyle } = this.state

    const { errorTitle, ...extraProps } = this.props

    const cur_title = this.state.errorTitle || errorTitle

    if (visible) {
      return <Alert
        bsStyle={bsStyle}
        onDismiss={this.hideAlert}
        {...extraProps}
      >
        <h4>{cur_title}</h4>
        {errorText &&
          <p>
            {errorText}
          </p>
        }
      </Alert>
    }

    return (<div />);
  }
}

AlertDismissable.defaultProps = {
  errorTitle: '',
};

AlertDismissable.propTypes = {
  errorTitle: PropTypes.string,
};
