import { Record, List } from "immutable";
import { ProfileType } from "models/profile";
import { LanguagePair } from "models/language_pair";
import { WriterContentType } from "models/profile/writer";
import LangStore from 'stores/langStore';

export const Requirement = defaultValues => class extends Record({
  profile_type: undefined,
  ...defaultValues
}) {
  static fromJS(jsObj) {
    jsObj['profile_type'] = ProfileType.get(jsObj['profile_type'])
    switch (jsObj['profile_type']) {
      case ProfileType.TRANSLATOR:
        return Translator.fromJS(jsObj)
      case ProfileType.TRANSCRIBER:
        return Transcriber.fromJS(jsObj)
      case ProfileType.WRITER:
        return Writer.fromJS(jsObj)
      default:
        throw new Error("Unknown Requirement Profile Type", jsObj)
    }
  }

}

export class Translator extends Requirement({
  lang_pair: undefined
}) {
  static fromJS(jsObj) {
    jsObj['lang_pair'] = LanguagePair.fromJS(jsObj['lang_pair'])
    return new Translator(jsObj)
  }

  get display() {
    return `TRANSLATE: ${this.lang_pair.display}`
  }
}

export class Transcriber extends Requirement({
  lang_pair: undefined
}) {
  static fromJS(jsObj) {
    jsObj['lang_pair'] = LanguagePair.fromJS(jsObj['lang_pair'])
    return new Transcriber(jsObj)
  }

  get display() {
    return `TRANSCRIBE: ${this.lang_pair.display}`
  }
}

export class Writer extends Requirement({
  src: undefined,
  content_types: List()
}) {
  static fromJS(jsObj) {
    jsObj['content_types'] = jsObj['content_types'].map(ct => WriterContentType.get(ct))
    return new Writer(jsObj)
  }

  get lang() {
    return LangStore.lang(this.src)
  }

  get display() {
    return `WRITE: ${this.lang.display} (${this.content_types.join(', ')})`
  }
}