import React from 'react';

import {
  Glyphicon
} from 'react-bootstrap';

import { ContactType } from 'models/contact';

const ContactGlyph = ({ contact_type }) => {
  switch (contact_type) {
    case ContactType.EMAIL:
      return (<Glyphicon glyph="envelope" data={contact_type} />);
    case ContactType.MOBILE:
      return (<Glyphicon glyph="earphone" data={contact_type} />);
    default:
      return <span />;
  }
};

export default ContactGlyph;
